import React, { useState, useEffect } from 'react';
import { useParams, Link, NavLink } from 'react-router-dom';

import { Grid, Col } from '../../components/Grid';
import Button from '../../components/Button';
import Notification from '../../components/Notification';

import { fetchNotificationsByStatus, markNotificationAsRead } from '../../services/notifications';
import { useFlash } from '../../components/FlashMessage';

import { capitalise } from '../../utils/strings';

import styles from './Notifications.module.scss';

const Notifications = ({ auth }) => {
  const { showFlash } = useFlash();
  const { status } = useParams();

  const [error, setError] = useState();
  const [notifications, setNotifications] = useState(null);

  const fetchNotifications = () => {
    fetchNotificationsByStatus(status)
      .then(setNotifications)
      .catch(setError);
  };

  useEffect(fetchNotifications, [status]);

  const markAsRead = id => {
    markNotificationAsRead(id)
      .then(() => {
        fetchNotifications();
        showFlash('success', 'Notifcation marked as read');
      })
      .catch(error => showFlash('error', error));
  };

  const { role } = auth.user;
  const canManageNotification = ['admin', 'ho'].includes(role);

  return (
    <>
      <Grid>
        <Col>
          <Link to="/">&laquo; Back to Dashboard</Link>
        </Col>
        <Col width="auto">
          <h1>{capitalise(status)} Notifications</h1>
        </Col>
        <Col className="range-right">
          {canManageNotification && <Button href="/notifications/add">+ Add Notification</Button>}
        </Col>
      </Grid>

      <Grid className={styles['status-toggle']} align="center" data-testid="status-toggle">
        <Col width="auto">
          {canManageNotification ? (
            <NavLink
              to="/notifications/draft"
              className={styles.toggle}
              activeClassName={styles.active}
            >
              Draft
            </NavLink>
          ) : (
            <NavLink
              to="/notifications/unread"
              className={styles.toggle}
              activeClassName={styles.active}
            >
              Unread
            </NavLink>
          )}
        </Col>
        <Col width="auto">
          {canManageNotification ? (
            <NavLink
              to="/notifications/published"
              className={styles.toggle}
              activeClassName={styles.active}
            >
              Published
            </NavLink>
          ) : (
            <NavLink
              to="/notifications/read"
              className={styles.toggle}
              activeClassName={styles.active}
            >
              Read
            </NavLink>
          )}
        </Col>
      </Grid>

      {error && <p className="heavy range-center error">{error}</p>}

      {notifications && notifications.length === 0 && (
        <p className="lg range-center">No {status} notfications</p>
      )}

      {notifications &&
        notifications.length > 0 &&
        notifications.map(notification => (
          <Notification
            notification={notification}
            auth={auth}
            onRead={markAsRead}
            key={notification._id}
          />
        ))}
    </>
  );
};

export default Notifications;

import React, { useState, useEffect } from 'react';
import { withFormik, FieldArray } from 'formik';

import Button from '../Button';
import FormField from '../FormField';
import { Grid, Col } from '../Grid';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '../Tabs';

import { STORES, COUNTRIES } from '../../config';
import defaultValues from './defaultValues';
import validationSchema from './validationSchema';
import { inputDate } from '../../utils/dates';

const CustomerForm = ({ mode, values, errors, touched, handleSubmit }) => {
  // Maintain a list of invalid tabs
  const [invalidTabs, setInvalidTabs] = useState([]);

  // Check for existence of errors in each form section
  useEffect(() => {
    const invalidTabs = [];
    // Customer details
    if (
      (touched.firstname && errors.firstname) ||
      (touched.lastname && errors.lastname) ||
      (touched.email && errors.email) ||
      (touched.phone && errors.phone) ||
      (touched.mobile && errors.mobile) ||
      (touched.birthday && errors.birthday) ||
      (touched.anniversary && errors.anniversary)
    ) {
      invalidTabs.push('details');
    }

    // Address
    if (errors.address && touched.address) {
      // loop over the address errors
      Object.keys(errors.address).some(key => {
        // only highlight if the field is touched as well!
        if (touched.address[key]) {
          invalidTabs.push('address');
          return true;
        }
        return false;
      });
    }

    // VIP Membership
    if ((touched.isVip && errors.isVip) || (touched.vipNumber && errors.vipNumber)) {
      invalidTabs.push('vip');
    }
    // Free Gifts
    touched.freeGifts &&
      touched.freeGifts.forEach((_, index) => {
        if (touched.freeGifts[index] && errors.freeGifts && errors.freeGifts[index]) {
          invalidTabs.push('gifts');
        }
      });

    // Notes
    touched.notes &&
      touched.notes.forEach((_, index) => {
        if (touched.notes[index] && errors.notes && errors.notes[index]) {
          invalidTabs.push('notes');
        }
      });

    // Internal
    if (touched.signupStore && errors.signupStore) {
      invalidTabs.push('internal');
    }

    setInvalidTabs(invalidTabs);
  }, [errors, touched]);

  return (
    <form onSubmit={handleSubmit}>
      <Tabs>
        <TabList>
          <Tab error={invalidTabs.includes('details')}>Customer Details</Tab>
          <Tab error={invalidTabs.includes('address')}>Customer Address</Tab>
          <Tab error={invalidTabs.includes('vip')}>VIP Membership</Tab>
          <Tab error={invalidTabs.includes('gifts')}>Free Gifts ({values.freeGifts.length})</Tab>
          {mode === 'add' && (
            <Tab error={invalidTabs.includes('notes')}>Notes ({values.notes.length})</Tab>
          )}
          <Tab error={invalidTabs.includes('internal')}>Internal</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Grid gutters="md">
              <Col width="6">
                <FormField type="text" name="firstname" id="firstname" label="First Name" />
              </Col>
              <Col width="6">
                <FormField type="text" name="lastname" id="lastname" label="Last Name" />
              </Col>
              <Col width="6">
                <FormField type="email" name="email" id="email" label="Email Address" />
              </Col>
              <Col width="6">
                <FormField
                  type="radios"
                  name="gender"
                  label="Gender"
                  options={['Male', 'Female']}
                />
              </Col>
              <Col width="6">
                <FormField type="tel" name="phone" id="phone" label="Phone Number" />
              </Col>
              <Col width="6">
                <FormField type="tel" name="mobile" id="mobile" label="Mobile Number" />
              </Col>
              <Col width="6">
                <FormField
                  type="date"
                  name="birthday"
                  id="birthday"
                  label="Birthday"
                  max={new Date()}
                />
              </Col>
              <Col width="6">
                <FormField
                  type="date"
                  name="anniversary"
                  id="anniversary"
                  label="Anniversary Date"
                />
              </Col>
            </Grid>
          </TabPanel>

          <TabPanel>
            <Grid gutters="md">
              <Col width="6">
                <FormField
                  type="text"
                  name="address.street_1"
                  id="address.street_1"
                  label="Line 1"
                />
              </Col>
              <Col width="6">
                <FormField
                  type="text"
                  name="address.street_2"
                  id="address.street_2"
                  label="Line 2"
                />
              </Col>
              <Col width="6">
                <FormField type="text" name="address.city" id="address.city" label="City" />
              </Col>
              <Col width="6">
                <FormField type="text" name="address.region" id="address.region" label="Region" />
              </Col>
              <Col width="6">
                <FormField
                  type="text"
                  name="address.postcode"
                  id="address.postcode"
                  label="Postcode"
                />
              </Col>
              <Col width="6">
                <FormField
                  type="select"
                  name="address.country"
                  id="address.country"
                  label="Country"
                  options={COUNTRIES}
                />
              </Col>
            </Grid>
          </TabPanel>

          <TabPanel>
            <Grid gutters="md">
              <Col width="6">
                <FormField
                  type="radios"
                  name="isVip"
                  label="VIP Member?"
                  options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                />
              </Col>
              {values.isVip.toString() === 'true' && (
                <Col width="6">
                  <FormField type="text" name="vipNumber" id="vipNumber" label="Membership No." />
                </Col>
              )}
            </Grid>
          </TabPanel>

          <TabPanel>
            <FieldArray
              name="freeGifts"
              id="freeGifts"
              render={arrayHelpers => (
                <Grid gutters="md">
                  {values.freeGifts.map((gift, index) => (
                    <Col width="12" key={index}>
                      <Grid gutters="sm">
                        <Col>
                          <FormField
                            type="text"
                            name={`freeGifts.${index}.description`}
                            label="Description"
                          />
                        </Col>
                        <Col width="auto">
                          <Button format="danger" onClick={() => arrayHelpers.remove(index)}>
                            Delete
                          </Button>
                        </Col>
                      </Grid>
                    </Col>
                  ))}

                  {!values.freeGifts.length && (
                    <Col width="12">
                      <p>No free gifts given to customer.</p>
                    </Col>
                  )}

                  <Col width="12">
                    <Button
                      type="button"
                      format="primary"
                      onClick={() => arrayHelpers.push({ description: '' })}
                    >
                      Add Gift
                    </Button>
                  </Col>
                </Grid>
              )}
            />
          </TabPanel>

          {mode === 'add' && (
            <TabPanel>
              <FieldArray
                name="notes"
                id="notes"
                render={arrayHelpers => (
                  <Grid gutters="md">
                    {values.notes.map((gift, index) => (
                      <Col width="12" key={index}>
                        <Grid gutters="sm">
                          <Col>
                            <FormField
                              type="textarea"
                              name={`notes.${index}.content`}
                              label="Note"
                            />
                          </Col>
                          <Col width="auto">
                            <Button format="danger" onClick={() => arrayHelpers.remove(index)}>
                              Delete
                            </Button>
                          </Col>
                        </Grid>
                      </Col>
                    ))}

                    {!values.notes.length && (
                      <Col width="12">
                        <p>No notes added to customer.</p>
                      </Col>
                    )}

                    <Col width="12">
                      <Button
                        type="button"
                        format="primary"
                        onClick={() => arrayHelpers.push({ note: '' })}
                      >
                        Add Note
                      </Button>
                    </Col>
                  </Grid>
                )}
              />
            </TabPanel>
          )}

          <TabPanel>
            <Grid gutters="md">
              <Col width="6">
                <FormField
                  type="select"
                  name="signupStore"
                  id="signupStore"
                  label="Signup Store"
                  options={STORES}
                />
              </Col>
            </Grid>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <p className="range-right">
        <Button type="submit">Submit</Button>
      </p>
    </form>
  );
};

export default withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    ...defaultValues,
    ...initialValues,
    birthday: inputDate(initialValues.birthday),
    anniversary: inputDate(initialValues.anniversary)
  }),
  validationSchema,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  }
})(CustomerForm);

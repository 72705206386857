import React from 'react';
import { Formik, Form } from 'formik';

import Button from '../../components/Button';
import { Grid, Col } from '../../components/Grid';

import FormField from '../../components/FormField';

import defaultValues from './defaultValues';
import generateValidationSchema from './validationSchema';
import { USER_ROLES, STORES } from '../../config';
import PasswordHints from '../PasswordHints';

const UserForm = ({ initialValues, onSubmit, onChangePasswordClick }) => {
  return (
    <>
      <Formik
        initialValues={{ ...defaultValues, ...initialValues }}
        validationSchema={generateValidationSchema(initialValues ? 'edit' : 'add')}
        onSubmit={onSubmit}
        render={({ values }) => (
          <>
            <Form>
              <Grid gutters="md">
                <Col width="6">
                  <FormField type="text" name="firstname" label="First Name" />
                </Col>
                <Col width="6">
                  <FormField type="text" name="lastname" label="Last Name" />
                </Col>
                <Col width="6">
                  <FormField
                    type="select"
                    name="role"
                    label="User Type"
                    options={Object.entries(USER_ROLES).map(([key, value]) => ({
                      label: value,
                      value: key
                    }))}
                  />
                </Col>
                <Col width="6">
                  {values.role === 'staff' && (
                    <FormField type="select" name="store" label="Store" options={STORES} />
                  )}
                </Col>
                <Col width="6">
                  <FormField type="text" name="username" label="Username" />
                </Col>
                <Col width="6">
                  {!initialValues && (
                    <>
                      <FormField type="password" name="password" label="Password" />
                      <PasswordHints />
                    </>
                  )}
                </Col>
                <Col width="12" className="range-right">
                  {onChangePasswordClick && (
                    <Button format="secondary" onClick={onChangePasswordClick}>
                      Change User Password
                    </Button>
                  )}
                  <Button type="submit">Save User</Button>
                </Col>
              </Grid>
            </Form>
          </>
        )}
      />
    </>
  );
};

export default UserForm;

import React from 'react';
import { connect, getIn } from 'formik';
import classnames from 'classnames';

import Input from '../Input';
import Radios from '../Radios';
import Checkboxes from '../Checkboxes';
import Select from '../Select';
import CurrencyInput from '../CurrencyInput';
import Textarea from '../Textarea';

import styles from './FormField.module.scss';
import DatePicker from '../DatePicker';

const FormField = ({
  name,
  label,
  type,
  options = null,
  rows = 3,
  disabled = false,
  placeholder,
  min,
  max,
  formik
}) => {
  const touched = getIn(formik.touched, name);
  const error = getIn(formik.errors, name);
  const isInvalid = touched && error;

  const renderInput = () => {
    switch (type) {
      case 'radios':
        return (
          <Radios
            name={name}
            disabled={disabled}
            options={options}
            className={classnames(styles.control, styles.radios)}
          />
        );
      case 'checkboxes':
        return (
          <Checkboxes
            name={name}
            disabled={disabled}
            options={options}
            className={classnames(styles.control, styles.checkboxes)}
          />
        );
      case 'select':
        return (
          <Select
            name={name}
            disabled={disabled}
            options={options}
            placeholder={placeholder}
            className={styles.control}
          />
        );
      case 'textarea':
        return (
          <Textarea
            name={name}
            disabled={disabled}
            rows={rows}
            placeholder={placeholder}
            className={styles.control}
          />
        );

      case 'currency':
        return (
          <CurrencyInput
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            className={styles.control}
          />
        );

      case 'date':
        return (
          <DatePicker
            name={name}
            disabled={disabled}
            className={styles.control}
            min={min}
            max={max}
          />
        );
      default:
        return (
          <Input
            type={type}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            className={styles.control}
          />
        );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={classnames([styles.field, isInvalid && styles.invalid])}>
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
        {renderInput()}
      </div>
      {isInvalid && type !== 'password' && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default connect(FormField);

import * as Yup from 'yup';

import { VALIDATION_REQUIRED, STORES } from '../../config';

export default Yup.object().shape({
  itemType: Yup.string()
    .oneOf(['purchase', 'manual'])
    .required('Please select a purchase or enter the item details manually'),
  purchase: Yup.string().when('itemType', {
    is: 'purchase',
    then: Yup.string().required('Please select a purchase or enter the item details manually')
  }),
  itemDescription: Yup.string().when('itemType', {
    is: 'manual',
    then: Yup.string().required('Please enter the item description or select a purchase')
  }),
  dateBooked: Yup.date().required(VALIDATION_REQUIRED),
  store: Yup.string()
    .oneOf(STORES)
    .required(VALIDATION_REQUIRED),
  repairDescription: Yup.string().required(VALIDATION_REQUIRED),
  repairNumber: Yup.string().required(VALIDATION_REQUIRED),
  freeOfCharge: Yup.bool().required(VALIDATION_REQUIRED),
  freeOfChargeReaon: Yup.string().when('freeOfCharge', {
    is: true,
    then: Yup.string().required(VALIDATION_REQUIRED)
  }),
  quotedCost: Yup.number().when('freeOfCharge', {
    is: false,
    then: Yup.number().required(VALIDATION_REQUIRED)
  }),
  chargeableCost: Yup.number().when('freeOfCharge', {
    is: false,
    then: Yup.number().required(VALIDATION_REQUIRED)
  })
});

export default {
  // Details
  firstname: '',
  lastname: '',
  email: '',
  gender: '',
  phone: '',
  mobile: '',
  birthday: '',
  anniversary: '',

  // Address
  address: {
    street_1: '',
    street_2: '',
    city: '',
    region: '',
    postcode: '',
    country: ''
  },

  // VIP
  isVip: '',
  vipNumber: '',

  // Gifts
  freeGifts: [],

  // Notes
  notes: [],

  // Internal
  signupStore: '',
  signupSource: 'IN_STORE'
};

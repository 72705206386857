import React, { useState } from 'react';
import AutoSuggest from 'react-autosuggest';
import styles from './SearchBar.module.scss';

import { useFlash } from '../FlashMessage';

const SearchBar = ({ fieldOptions, fetchResults, renderSuggestion, onSelect }) => {
  const { showFlash } = useFlash();
  // Search State
  const [field, setField] = useState(fieldOptions[0].value);
  const [query, setQuery] = useState('');
  // Results state
  const [results, setResults] = useState([]);

  const handleSuggestionsFetchRequested = ({ value }) => {
    if (value.length < 3) {
      return setResults([]);
    }

    fetchResults(field, value)
      .then(response => setResults(response))
      .catch(error => {
        setResults([]);
        showFlash('error', error);
      });
  };

  const handleResultSelect = (event, { suggestionValue }) => {
    onSelect(suggestionValue);
  };

  return (
    <div className={styles.searchbar}>
      <label htmlFor="field" className="reader-text">
        Search by
      </label>
      <select
        name="field"
        id="field"
        value={field}
        className={styles.fieldselect}
        onChange={e => setField(e.target.value)}
      >
        {fieldOptions.map(field => (
          <option key={field.value} value={field.value}>
            {field.label}
          </option>
        ))}
      </select>

      <label htmlFor="query" className="reader-text">
        Search term
      </label>
      <AutoSuggest
        suggestions={results}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={(...args) => {}}
        getSuggestionValue={result => result}
        onSuggestionSelected={handleResultSelect}
        renderSuggestion={result => (
          <div className={styles.result}>{renderSuggestion(result, field)}</div>
        )}
        inputProps={{
          type: 'search',
          name: 'query',
          id: 'query',
          value: query,
          className: styles.query,
          onChange: e => {
            setQuery(e.target.value || '');
          }
        }}
      />
    </div>
  );
};

export default SearchBar;

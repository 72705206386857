import React from 'react';
import { Link } from 'react-router-dom';

import { Grid, Col } from '../../components/Grid';
import NotificationForm from '../../components/NotificationForm';

import { useFlash } from '../../components/FlashMessage';
import { addNotification } from '../../services/notifications';

const AddNotification = () => {
  const { redirectWithFlash, showFlash } = useFlash();

  const handleSubmit = notification => {
    addNotification(notification)
      .then(() => {
        redirectWithFlash(
          `/notifications/${notification.status}`,
          'success',
          `Notification ${notification.status === 'draft' ? 'saved as draft!' : 'published!'}`
        );
      })
      .catch(error => showFlash('error', error));
  };

  return (
    <>
      <Grid>
        <Col>
          <Link to="/notifications/draft">&laquo; Back to Notifications</Link>
        </Col>
        <Col width="auto">
          <h1>Add Notification</h1>
        </Col>
        <Col></Col>
      </Grid>

      <NotificationForm onSubmit={handleSubmit} />
    </>
  );
};

export default AddNotification;

import React from 'react';

// Components
import QuickLinks from '../../components/QuickLinks';

const QuickSearchIndex = () => {
  return (
    <>
      <h1>Quick Search</h1>
      <p className="lg range-center">What would you like to search for?</p>

      <QuickLinks
        links={[
          {
            text: 'Customer',
            href: '/quick-search/customers'
          },
          {
            text: 'Purchase',
            href: '/quick-search/purchases'
          }
        ]}
      />
    </>
  );
};

export default QuickSearchIndex;

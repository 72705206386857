import React from 'react';
import { createPortal } from 'react-dom';

import Icon from '../Icon';

import styles from './Modal.module.scss';

const Modal = ({ isOpen, onClose = null, className, children }) => {
  if (!isOpen) return null;

  return createPortal(
    <div className={styles.modal} role="dialog" aria-modal="true">
      <div className={`${styles.content} ${className}`}>
        {onClose && (
          <button type="button" onClick={onClose} aria-label="Close" className={styles.close}>
            <Icon icon="close" />
          </button>
        )}
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;

import axios from 'axios';

export const fetchUsers = query =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/users`, { params: query })
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const fetchUserById = id =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/users/${id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const addUser = user =>
  new Promise((resolve, reject) => {
    axios
      .post(`/api/users`, user)
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const updateUser = (id, updates) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/api/users/${id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const changeUserPassword = (id, password) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/api/users/${id}/password`, { password })
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const deleteUser = id =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/api/users/${id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const fetchStaffMembersByStore = store =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/users/staff`, { params: { store } })
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

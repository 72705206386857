import * as Yup from 'yup';

export const MIV_ERROR = 'Please enter a maximum insurable value';
export const ASSETSURE_EMAIL_ERROR = 'Please enter a valid email for Assetsure notifications';
export const ADMIN_EMAIL_ERROR = 'Please enter a valid email for Admin notifications';

export default Yup.object().shape({
  maxInsurableValue: Yup.string().required(MIV_ERROR),
  assetsureNotificationEmail: Yup.string()
    .email(ASSETSURE_EMAIL_ERROR)
    .required(ASSETSURE_EMAIL_ERROR),
  adminNotificationEmail: Yup.string()
    .email(ADMIN_EMAIL_ERROR)
    .required(ADMIN_EMAIL_ERROR)
});

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';

import { useFlash } from '../FlashMessage';
import { AUTH_SESSION_EXPIRED_MESSAGE, AUTH_WRONG_ROLE_MESSAGE } from '../../config';

import Layout from '../Layout';

const AuthRoute = ({ roles, children, centered = false, dark = false, ...rest }) => {
  const { redirectWithFlash } = useFlash();
  const token = cookie.get('token');

  // No token - Redirect to login
  if (!token) {
    return <Redirect to="/login" />;
  }

  // Decode the token
  const decodedToken = jwtDecode(token);

  // Token Expired - Redirect to login
  if (new Date().getTime() / 1000 > decodedToken.exp) {
    redirectWithFlash('/login', 'error', AUTH_SESSION_EXPIRED_MESSAGE);
    return null;
  }

  // Wrong role - Redirect to dashboard
  if (roles && roles.length && !roles.includes(decodedToken.user.role)) {
    redirectWithFlash('/', 'error', AUTH_WRONG_ROLE_MESSAGE);
    return null;
  }

  return (
    <Route {...rest}>
      <Layout centered={centered} dark={dark} auth={decodedToken}>
        {React.cloneElement(children, { auth: decodedToken })}
      </Layout>
    </Route>
  );
};

export default AuthRoute;

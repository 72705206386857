import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Button.module.scss';

const Button = ({
  format = 'primary',
  href = null,
  type = 'button',
  onClick = null,
  disabled = false,
  children,
  download = false
}) => {
  let className = `${styles.btn} ${styles[format]}`;

  if (download) {
    return (
      <a href={href} className={className} download={true} onClick={onClick}>
        {children}
      </a>
    );
  }

  if (href) {
    return (
      <Link to={href} className={className} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return (
    <button className={className} type={type} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;

import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import queryString from 'query-string';
import { Formik, Form } from 'formik';

import Button from '../../components/Button';
import Checkboxes from '../../components/Checkboxes';
import Icon from '../../components/Icon';
import Radios from '../../components/Radios';

import buildInitialValues from './buildInitialValues';

import styles from './Filters.module.scss';

const Filters = ({ open = false, onClose, options = [], defaultValues }) => {
  const location = useLocation();
  const history = useHistory();

  const currentQuery = {
    ...defaultValues,
    ...queryString.parse(location.search)
  };

  const transition = useSpring({
    transform: open ? 'translateX(0)' : 'translateX(-100%)'
  });

  return (
    <animated.div className={styles.filters} style={transition}>
      <Formik
        initialValues={buildInitialValues(options, currentQuery)}
        onSubmit={values => {
          history.push({
            pathname: location.pathname,
            search: queryString.stringify({ ...currentQuery, ...values, page: 1 })
          });
          onClose();
        }}
        render={({ resetForm }) => (
          <Form>
            <button
              type="button"
              onClick={() => {
                resetForm();
                onClose();
              }}
              className={styles.close}
              aria-label="Close Filters"
            >
              <Icon icon="close" />
            </button>

            {options.map(option => (
              <div className={styles['filter-group']} key={option.label}>
                <h5 className="tight">{option.label}</h5>
                {option.type === 'multiple' && (
                  <Checkboxes name={option.name} options={option.options} block />
                )}
                {option.type === 'single' && (
                  <Radios name={option.name} options={option.options} block />
                )}
              </div>
            ))}

            <Button type="submit">Apply Filters</Button>
          </Form>
        )}
      />
    </animated.div>
  );
};

export default Filters;

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
// Components
import { Grid, Col } from '../../components/Grid';
import Button from '../../components/Button';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '../../components/Tabs';
import NotesList from '../../components/NotesList';
import RepairProgressModal from '../../components/RepairProgressModal';
import MultilineText from '../../components/MultilineText';
import { useFlash } from '../../components/FlashMessage';

import styles from './Repair.module.scss';

// Services, Config etc.
import {
  fetchRepairById,
  addRepairNote,
  deleteRepairNote,
  addRepairProgress
} from '../../services/repairs';
import { displayDate } from '../../utils/dates';
import { money } from '../../utils/money';

const Repair = ({ auth }) => {
  const { repairId } = useParams();
  const [repair, setRepair] = useState(null);
  const [error, setError] = useState(null);
  const { showFlash } = useFlash();
  const [showProgressModal, setShowProgressModal] = useState(false);

  const fetchRepair = () => {
    fetchRepairById(repairId)
      .then(repair => {
        setRepair(repair);
        setError(null);
      })
      .catch(error => {
        setError(error);
        setRepair(null);
      });
  };

  useEffect(fetchRepair, [repairId]);

  const addNote = note => {
    addRepairNote(repairId, note)
      .then(() => {
        fetchRepair();
        showFlash('success', 'Note added');
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  const deleteNote = noteId => {
    deleteRepairNote(repairId, noteId)
      .then(() => {
        fetchRepair();
        showFlash('success', 'Note deleted');
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  const addProgress = values => {
    addRepairProgress(repairId, values)
      .then(() => {
        setShowProgressModal(false);
        fetchRepair();
        showFlash('success', 'Repair Updated');
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  return (
    <>
      <Grid>
        <Col>
          <Link to="/repairs">&laquo; Back to Repairs</Link>
        </Col>
        <Col width="auto">
          <h1>Repair Details</h1>
        </Col>
        <Col className="range-right">
          {['admin', 'ho'].includes(auth.user.role) && (
            <Button href={`/repairs/${repairId}/edit`}>Edit Repair</Button>
          )}
        </Col>
      </Grid>

      {error && <p className="lg range-center error">{error}</p>}

      {repair && (
        <Tabs>
          <TabList>
            <Tab>Item Details</Tab>
            <Tab>Repair Details</Tab>
            <Tab>Cost</Tab>
            <Tab>Notes ({repair.notes.length})</Tab>
            <Tab>Repair Progress</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {repair.itemDescription && (
                <>
                  <strong>Item Description</strong>
                  <br />
                  {repair.itemDescription}
                </>
              )}

              {repair.purchase && (
                <Grid gutters="md">
                  <Col width="12">
                    <strong>Item Description</strong>
                    <br />
                    {repair.purchase.description}
                  </Col>
                  <Col width="6">
                    <strong>Invoice Number</strong>
                    <br />
                    {repair.purchase.invoiceNumber}
                  </Col>
                </Grid>
              )}
            </TabPanel>

            <TabPanel>
              <Grid gutters="md">
                <Col width="12">
                  <strong>Repair Description</strong>
                  <br />
                  {repair.repairDescription}
                </Col>
                <Col width="6">
                  <strong>Date Booked</strong>
                  <br />
                  {displayDate(repair.dateBooked)}
                </Col>
                <Col width="6">
                  <strong>Store</strong>
                  <br />
                  {repair.store}
                </Col>
                <Col width="6">
                  <strong>Repair Number</strong>
                  <br />
                  {repair.repairNumber}
                </Col>
              </Grid>
            </TabPanel>

            <TabPanel>
              <Grid gutters="md">
                <Col width="6">
                  <strong>Free of Charge?</strong>
                  <br />
                  {repair.freeOfCharge ? 'Yes' : 'No'}
                </Col>
                <Col width="6"></Col>
                {repair.freeOfCharge ? (
                  <Col width="12">
                    <strong>Reason</strong>
                    <br />
                    {repair.freeOfChargeReason}
                  </Col>
                ) : (
                  <>
                    <Col width="6">
                      <strong>Quoted Cost</strong>
                      <br />
                      {money(repair.quotedCost)}
                    </Col>
                    <Col width="6">
                      <strong>Chargeable Cost</strong>
                      <br />
                      {money(repair.chargeableCost)}
                    </Col>
                  </>
                )}
              </Grid>
            </TabPanel>

            <TabPanel>
              <NotesList
                notes={repair.notes}
                user={auth.user}
                addNote={addNote}
                deleteNote={deleteNote}
                noNotesMessage="No notes added to purchase"
              />
            </TabPanel>

            <TabPanel>
              {!repair.progress.length ? (
                <p>No progress so far</p>
              ) : (
                <table className={styles.progressTable}>
                  <thead>
                    <tr>
                      <th className={styles.eventCol}>Event</th>
                      <th className={styles.dateCol}>Date</th>
                      <th>Note</th>
                    </tr>
                  </thead>
                  <tbody>
                    {repair.progress.map(progress => (
                      <tr key={progress._id}>
                        <td className={styles.eventCol}>{progress.event}</td>
                        <td className={styles.dateCol}>{displayDate(progress.date)}</td>
                        <td>
                          <MultilineText text={progress.note} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {!repair.progress.find(progress => progress.event === 'Collected') && (
                <>
                  <Button onClick={() => setShowProgressModal(true)}>Log Progress</Button>
                  <RepairProgressModal
                    currentProgress={repair.progress}
                    isOpen={showProgressModal}
                    onClose={() => setShowProgressModal(false)}
                    onSubmit={addProgress}
                  />
                </>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </>
  );
};

export default Repair;

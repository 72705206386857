import React from 'react';
import classnames from 'classnames';
import { connect, getIn } from 'formik';

import styles from './Radios.module.scss';

const Radios = ({ name, options, disabled = false, className = null, block = false, formik }) => {
  const radioOptions = options.map(option =>
    typeof option === 'string'
      ? {
          label: option,
          value: option
        }
      : option
  );

  return (
    <div className={classnames(styles.radios, className)}>
      {radioOptions.map(({ value, label }) => (
        <label className={classnames(styles.radio, block && styles.block)} key={value}>
          <input
            className={styles.input}
            type="radio"
            name={name}
            id={name}
            value={value}
            disabled={disabled}
            checked={getIn(formik.values, name).toString() === value.toString()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <span className={styles.label}>{label}</span>
        </label>
      ))}
    </div>
  );
};

export default connect(Radios);

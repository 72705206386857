import React from 'react';
import { Formik, Form } from 'formik';

import Modal from '../Modal';
import { Grid, Col } from '../Grid';
import FormField from '../FormField';
import Button from '../Button';

import initialValues from './initialValues';
import validationSchema from './validationSchema';

import { REPAIR_PROGRESS_EVENTS } from '../../config';

const RepairProgressModal = ({ currentProgress = [], isOpen, onClose, onSubmit }) => {
  // Cant select a progress event that has already happened
  const currentProgressEvents = currentProgress.map(progress => progress.event);
  const eventOptions = REPAIR_PROGRESS_EVENTS.filter(
    event => !currentProgressEvents.includes(event)
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        <Form>
          <h3 className="primary">Add Progress Event</h3>
          <Grid gutters="md">
            <Col width="6">
              <FormField
                name="event"
                id="event"
                type="select"
                options={eventOptions}
                label="Event"
              />
            </Col>
            <Col width="6">
              <FormField name="date" id="date" type="date" label="Date" max={new Date()} />
            </Col>
            <Col width="12">
              <FormField name="note" id="note" type="textarea" rows="3" label="Note" />
            </Col>
            <Col width="12" className="range-right">
              <Button format="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </Col>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  );
};

export default RepairProgressModal;

import React from 'react';
import classnames from 'classnames';
import { Field } from 'formik';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import styles from '../Input/Input.module.scss';

const mask = createNumberMask({
  prefix: '£',
  allowDecimal: true
});
const backToNumber = value =>
  value
    .replace(/£/g, '')
    .replace(/_/g, '')
    .replace(/,/g, '');

const Input = ({ name, disabled = false, className = null }) => {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <MaskedInput
          type="text"
          inputMode="numeric"
          className={classnames(styles.input, className)}
          id={name}
          disabled={disabled}
          {...field}
          mask={mask}
          onChange={event => {
            console.log(event.currentTarget.value);
            // Convert the masked value back to a number to save in formik values
            form.setFieldValue(name, backToNumber(event.target.value));
          }}
        />
      )}
    </Field>
  );
};

export default Input;

import React from 'react';
import { FieldArray } from 'formik';

import Button from '../Button';
import { Grid, Col } from '../Grid';
import FormField from '../FormField';

const PurchaseNotes = ({ values }) => {
  return (
    <FieldArray
      name="notes"
      id="notes"
      render={arrayHelpers => (
        <Grid gutters="md">
          {values.notes.map((note, index) => (
            <Col width="12" key={index}>
              <Grid gutters="sm">
                <Col>
                  <FormField type="textarea" name={`notes.${index}.content`} label="Note" />
                </Col>
                <Col width="auto">
                  <Button type="button" format="danger" onClick={() => arrayHelpers.remove(index)}>
                    Delete
                  </Button>
                </Col>
              </Grid>
            </Col>
          ))}

          {!values.notes.length && (
            <Col width="12">
              <p>No notes added to purchase.</p>
            </Col>
          )}

          <Col width="12">
            <Button
              type="button"
              format="primary"
              onClick={() => arrayHelpers.push({ content: '' })}
            >
              Add Note
            </Button>
          </Col>
        </Grid>
      )}
    />
  );
};
export default PurchaseNotes;

import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import classnames from 'classnames';
import startOfDay from 'date-fns/startOfDay';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';

// Components
import { Grid, Col } from '../../components/Grid';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Filters from '../../components/Filters';
import Pagination from '../../components/Pagination';
import ResultsTable from '../../components/ResultsTable';

// Services, Config etc.
import { fetchPurchasesWithWarranties } from '../../services/purchases';
import { useFlash } from '../../components/FlashMessage';

// Styles
import styles from './Warranties.module.scss';
import { STORES } from '../../config';

const Warranties = ({ auth }) => {
  const { search } = useLocation();
  const history = useHistory();
  const [showFilters, setShowFilters] = useState(false);
  const [purchases, setPurchases] = useState(null);
  const { showFlash } = useFlash();

  // Perform request whenever the query string changes
  useEffect(() => {
    const defaultQuery = { sortBy: 'purchaseDate', order: 'asc', page: 1, status: 'all' };
    const currentQuery = queryString.parse(search);

    fetchPurchasesWithWarranties({
      ...defaultQuery,
      ...currentQuery
    })
      .then(setPurchases)
      .catch(error => showFlash('error', error));
  }, [search, showFlash]);

  return (
    <>
      <Grid>
        <Col>
          <Button format="secondary" onClick={() => setShowFilters(true)}>
            <Icon icon="filter" /> Show filters
          </Button>
        </Col>
        <Col width="auto">
          <h1>Warranties</h1>
        </Col>
        <Col />
      </Grid>

      <Filters
        open={showFilters}
        onClose={() => setShowFilters(false)}
        options={[
          {
            label: 'Status',
            name: 'status',
            type: 'single',
            options: [
              { label: 'All', value: 'all' },
              { label: 'Due Soon', value: 'dueSoon' },
              { label: 'Overdue', value: 'overdue' },
              { label: 'Void', value: 'void' },
              { label: 'Complete', value: 'complete' }
            ]
          },
          {
            label: 'Store',
            name: 'store',
            type: 'multiple',
            options: STORES
          }
        ]}
        defaultValues={{
          status: 'all'
        }}
      />

      {purchases && purchases.records.length > 0 && (
        <>
          <ResultsTable
            config={{
              columns: [
                {
                  key: 'description',
                  label: 'Item Description'
                },
                {
                  key: 'itemType',
                  label: 'Item Type',
                  className: styles.type
                },
                {
                  key: 'store',
                  label: 'Purchase Store',
                  className: styles.store
                },
                {
                  key: 'purchaseDate',
                  label: 'Purchase Date',
                  format: 'date',
                  className: styles.date,
                  sortable: true
                },
                {
                  key: 'nextWarrantyCheckDueDate',
                  label: 'Next Check Due',
                  format: 'date',
                  className: styles.date,
                  sortable: true
                },
                {
                  key: 'status',
                  label: 'Status',
                  className: styles.statusCol,
                  format: purchase => {
                    const today = startOfDay(new Date());
                    const nextDueDate = purchase.nextWarrantyCheckDueDate
                      ? new Date(purchase.nextWarrantyCheckDueDate)
                      : null;

                    // No incomplete checks left - Complete
                    if (!nextDueDate) {
                      return (
                        <span className={classnames(styles.status, styles.complete)}>Complete</span>
                      );
                    }
                    // A check is due within the next month  - Due Soon
                    if (nextDueDate && today < nextDueDate && today >= subMonths(nextDueDate, 1)) {
                      return (
                        <span className={classnames(styles.status, styles.soon)}>Due Soon</span>
                      );
                    }
                    // A check is overdue, but not by more than three months - Overdue
                    if (nextDueDate && today >= nextDueDate && today < addMonths(nextDueDate, 3)) {
                      return (
                        <span className={classnames(styles.status, styles.overdue)}>Overdue</span>
                      );
                    }
                    // Missed a check date by more than three months - Void
                    if (nextDueDate && today > addMonths(nextDueDate, 3)) {
                      return <span className={classnames(styles.status, styles.void)}>Void</span>;
                    }

                    return ' - ';
                  }
                }
              ],
              onRowClick: _id => history.push(`/purchases/${_id}`)
            }}
            rows={purchases.records}
          />
          <Pagination
            total={purchases.total}
            perPage={purchases.perPage}
            currentPage={purchases.page}
          />
        </>
      )}

      {purchases && purchases.records.length < 1 && (
        <p className="lg range-center">No purchases with warranties found matching your query</p>
      )}
    </>
  );
};

export default Warranties;

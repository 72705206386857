import React from 'react';
import classnames from 'classnames';
import { Field } from 'formik';

import styles from './Input.module.scss';

const Input = ({ name, type, disabled = false, className = null }) => {
  return (
    <Field
      className={classnames(styles.input, className)}
      type={type}
      name={name}
      id={name}
      disabled={disabled}
    />
  );
};

export default Input;

import axios from 'axios';

export const fetchRepairs = query =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/repairs`, { params: query })
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const fetchRepairById = id =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/repairs/${id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const fetchRepairsByPurchaseId = id =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/repairs/purchase/${id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const addRepair = values =>
  new Promise((resolve, reject) => {
    values.purchase = values.purchase || null;
    axios
      .post(`/api/repairs`, values)
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const addRepairProgress = (repairId, progress) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/api/repairs/${repairId}/progress`, progress)
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const addRepairNote = (purchaseId, note) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/api/repairs/${purchaseId}/notes`, note)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const deleteRepairNote = (purchaseId, noteId) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/api/repairs/${purchaseId}/notes/${noteId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

import React from 'react';

import QuickLinks from '../components/QuickLinks';

const Dashboard = ({ auth }) => {
  return (
    <>
      <h1>Welcome back {auth.user.firstname}</h1>
      <p className="lg range-center">
        Click on one of the links below or navigation above to access the system
      </p>
      <QuickLinks
        links={
          auth.user.role === 'assetsure'
            ? [
                {
                  text: 'Uninsured Purchases',
                  href: '/uninsured-purchases'
                },
                {
                  text: 'Unread Notifications',
                  href: '/notifications/unread'
                }
              ]
            : [
                {
                  text: 'Quick Search',
                  href: '/quick-search'
                },
                {
                  text: 'Record a Purchase',
                  href: '/purchases/add'
                },
                {
                  text: 'Sign up a new VIP',
                  href: '/customers/add'
                },
                {
                  text: 'Warranty Checks',
                  href: '/warranties'
                },
                {
                  text: 'Repairs',
                  href: '/repairs'
                }
              ]
        }
      />
    </>
  );
};

export default Dashboard;

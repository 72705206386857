import React from 'react';
import classnames from 'classnames';

import styles from './Grid.module.scss';

const Col = ({ width = null, className = null, children, ...rest }) => {
  const classes = classnames([styles.col, styles[`col--w-${width}`], className]);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default Col;

import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Grid, Col } from '../../components/Grid';
import RepairForm from '../../components/RepairForm';

// Misc
import { addRepair } from '../../services/repairs';
import { useFlash } from '../../components/FlashMessage';

const AddRepairPage = ({ auth }) => {
  const { showFlash, redirectWithFlash } = useFlash();

  const handleSubmit = values => {
    addRepair(values)
      .then(() => {
        redirectWithFlash('/repairs', 'success', 'Repair added successfully!');
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  return (
    <>
      <Grid>
        <Col>
          <Link to="/repairs">&laquo; Back to Repairs</Link>
        </Col>
        <Col width="auto">
          <h1>Add Repair</h1>
        </Col>
        <Col></Col>
      </Grid>

      <RepairForm
        mode="add"
        onSubmit={handleSubmit}
        initialValues={{ store: auth.user.role === 'staff' ? auth.user.store : '' }}
        auth={auth}
      />
    </>
  );
};

export default AddRepairPage;

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import { Grid, Col } from '../../components/Grid';
import Button from '../../components/Button';
import NotificationForm from '../../components/NotificationForm';

import { useFlash } from '../../components/FlashMessage';
import {
  fetchNotificationById,
  updateNotification,
  deleteNotification
} from '../../services/notifications';

const RESAVE_WARNINGS = {
  draft:
    "WARNING:\n\nThis will mark this notification as a draft and remove it from everyone's notifications list.\n\nDo you wish to continue?",
  published:
    "WARNING:\n\nRepublishing an already published notification will update it's publish date and mark it as unread for all recipients.\n\nDo you wish to conitinue?"
};

const EditNotification = () => {
  const { notificationId } = useParams();
  const { redirectWithFlash, showFlash } = useFlash();

  const [notification, setNotification] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchNotificationById(notificationId)
      .then(setNotification)
      .catch(setError);
  }, [notificationId]);

  const handleSubmit = updates => {
    // Show the pre-save warning first:
    if (
      // If the notification WAS published and is now being re-published or saved as draft the warning is shown
      (notification.status === 'published' && window.confirm(RESAVE_WARNINGS[updates.status])) ||
      // If the notification was draft in the first place then no warning is needed
      notification.status === 'draft'
    ) {
      // They agreed, continue with the update
      updateNotification(notificationId, updates)
        .then(() => {
          redirectWithFlash(
            `/notifications/${updates.status}`,
            'success',
            `Notification ${updates.status === 'draft' ? 'saved' : 'published'}!`
          );
        })
        .catch(error => showFlash('error', error));
    }
  };

  const showDeletePrompt = () => {
    console.log('Show warning!!');
    // Requires user action to confirm deletion
    if (
      window.prompt("Warning: This action cannot be undone. Please type 'DELETE' to confirm.") ===
      'DELETE'
    ) {
      deleteNotification(notificationId)
        .then(notification => {
          redirectWithFlash(
            `/notifications/${notification.status}`,
            'success',
            'Notification deleted!'
          );
        })
        .catch(error => showFlash('error', error));
    }
  };

  return (
    <>
      <Grid>
        <Col>
          <Link to="/notifications/draft">&laquo; Back to Notifications</Link>
        </Col>
        <Col width="auto">
          <h1>Edit Notification</h1>
        </Col>
        <Col className="range-right">
          {notification && (
            <Button format="danger" onClick={showDeletePrompt}>
              Delete Notification
            </Button>
          )}
        </Col>
      </Grid>

      {error && <p className="lg range-center">{error}</p>}

      {notification && <NotificationForm initialValues={notification} onSubmit={handleSubmit} />}
    </>
  );
};

export default EditNotification;

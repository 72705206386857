import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// Components
import { Grid, Col } from '../../components/Grid';
import Button from '../../components/Button';
import UserForm from '../../components/UserForm';
import Modal from '../../components/Modal';
import FormField from '../../components/FormField';
import PasswordHints from '../../components/PasswordHints';

// Services
import { fetchUserById, updateUser, deleteUser, changeUserPassword } from '../../services/users';
import { useFlash } from '../../components/FlashMessage';

import styles from './EditUserPage.module.scss';

const EditUserPage = () => {
  const { userId } = useParams();
  const { showFlash, redirectWithFlash } = useFlash();

  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const loadUser = () => {
    fetchUserById(userId)
      .then(setUser)
      .catch(error => {
        setError(error);
      });
  };

  // Fetch the user
  useEffect(loadUser, [userId]);

  const handleSubmit = values => {
    updateUser(userId, values)
      .then(() => {
        redirectWithFlash('/users', 'success', 'User updated!');
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  const handlePasswordChange = ({ password }) => {
    changeUserPassword(userId, password)
      .then(() => {
        showFlash('success', 'Password updated!');
        setShowPasswordModal(false);
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  const disableUser = () => {
    if (window.confirm('Are you sure you want to disable this user?')) {
      deleteUser(userId)
        .then(() => {
          showFlash('success', 'User disabled!');
          loadUser();
        })
        .catch(error => {
          showFlash('error', error);
        });
    }
  };

  const enableUser = () => {
    if (window.confirm('Are you sure you want to enable this user?')) {
      updateUser(userId, { disabled: false })
        .then(() => {
          showFlash('success', 'User enabled!');
          loadUser();
        })
        .catch(error => {
          showFlash('error', error);
        });
    }
  };

  return (
    <>
      <Grid>
        <Col>
          <Link to="/users">&laquo; Back to Users</Link>
        </Col>
        <Col width="auto">
          <h1>Edit User</h1>
        </Col>
        <Col className="range-right">
          {user && !user.disabled && (
            <Button format="danger" onClick={disableUser}>
              Disable User
            </Button>
          )}
          {user && user.disabled && (
            <Button format="danger" onClick={enableUser}>
              Enable User
            </Button>
          )}
        </Col>
      </Grid>

      {error && <p className="lg range-center error">{error}</p>}

      {user && (
        <UserForm
          onSubmit={handleSubmit}
          initialValues={user}
          onChangePasswordClick={() => setShowPasswordModal(true)}
        />
      )}

      <Modal
        isOpen={showPasswordModal}
        onClose={() => setShowPasswordModal(false)}
        className={styles['password-modal']}
      >
        <Formik
          initialValues={{ password: '' }}
          onSubmit={handlePasswordChange}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .matches(/[A-Z]/) // contains uppercase letter
              .matches(/[a-z]/) // contains lowercase letter
              .matches(/[^a-zA-Z\d\s:]/) // contains a symbol
              .matches(/\d/) // contains a number
              .min(8) // is at least 8 characters
              .required()
          })}
        >
          <Form>
            <h3 className="primary">Set a new Password</h3>
            <FormField type="password" name="password" id="password" label="New Password" />
            <PasswordHints />
            <p className="range-right">
              <Button format="secondary" type="button" onClick={() => setShowPasswordModal(false)}>
                Cancel
              </Button>
              <Button type="submit">Save Password</Button>
            </p>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default EditUserPage;

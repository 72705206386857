import React from 'react';
import { Link } from 'react-router-dom';

import { Grid, Col } from '../Grid';

import styles from './QuickLinks.module.scss';

const QuickLinks = ({ links }) => {
  return (
    <div className={styles.quicklinks}>
      <Grid align="center" gutters="lg">
        {links.map(link => (
          <Col key={link.text} width="auto">
            {link.href && (
              <Link to={link.href} className={styles.link}>
                {link.text}
              </Link>
            )}
            {link.onClick && (
              <button onClick={link.onClick} className={styles.link}>
                {link.text}
              </button>
            )}
          </Col>
        ))}
      </Grid>
    </div>
  );
};

export default QuickLinks;

import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

// Components
import { Grid, Col } from '../../components/Grid';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Filters from '../../components/Filters';
import Pagination from '../../components/Pagination';
import ResultsTable from '../../components/ResultsTable';

// Services, Config etc.
import { fetchRepairs } from '../../services/repairs';
import { STORES, REPAIR_PROGRESS_EVENTS } from '../../config';

import styles from './Repairs.module.scss';

const Repairs = ({ auth }) => {
  const { search } = useLocation();
  const history = useHistory();
  const [showFilters, setShowFilters] = useState(false);
  const [repairs, setRepairs] = useState(null);

  // Perform request whenever the query string changes
  useEffect(() => {
    const defaultQuery = { sortBy: 'createdAt', order: 'asc', page: 1 };
    const currentQuery = queryString.parse(search);

    fetchRepairs({
      ...defaultQuery,
      ...currentQuery
    }).then(setRepairs);
  }, [search]);

  return (
    <>
      <Grid>
        <Col>
          <Button format="secondary" onClick={() => setShowFilters(true)}>
            <Icon icon="filter" /> Show filters
          </Button>
        </Col>
        <Col width="auto">
          <h1>Repairs</h1>
        </Col>
        <Col className="range-right">
          <Button format="primary" href="/repairs/add">
            + Add Repair
          </Button>
          {auth.user.role === 'admin' && (
            <Button format="secondary" href="/api/repairs/export" download>
              Export
            </Button>
          )}
        </Col>
      </Grid>

      <Filters
        open={showFilters}
        onClose={() => setShowFilters(false)}
        options={[
          ...(auth.user.role !== 'staff'
            ? [
                {
                  label: 'Store',
                  name: 'store',
                  type: 'multiple',
                  options: STORES
                }
              ]
            : []),
          {
            label: 'Free of Charge?',
            name: 'freeOfCharge',
            type: 'multiple',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]
          },
          {
            label: 'Progress',
            name: 'progress',
            type: 'multiple',
            options: REPAIR_PROGRESS_EVENTS
          }
        ]}
      />

      {repairs && repairs.records.length > 0 && (
        <>
          <ResultsTable
            config={{
              columns: [
                {
                  key: 'repairNumber',
                  label: 'Repair No.',
                  sortable: true,
                  className: styles.repairNumber
                },
                {
                  key: 'itemDescription',
                  label: 'Item  Description',
                  sortable: false,
                  format: repair =>
                    repair.purchase && repair.purchase.description
                      ? repair.purchase.description
                      : repair.itemDescription
                },
                ...(auth.user.role !== 'staff'
                  ? [
                      {
                        key: 'store',
                        label: 'Store',
                        sortable: true,
                        className: styles.store
                      }
                    ]
                  : []),
                {
                  key: 'dateBooked',
                  label: 'Date Booked',
                  sortable: true,
                  format: 'date',
                  className: styles.dateBooked
                },
                {
                  key: 'progress',
                  label: 'Progress',
                  sortable: true,
                  format: repair => {
                    const lastProgress = repair.progress[repair.progress.length - 1];
                    return lastProgress ? lastProgress.event : '-';
                  },
                  className: styles.progress
                }
              ],
              onRowClick: _id => history.push(`/repairs/${_id}`)
            }}
            rows={repairs.records}
          />
          <Pagination total={repairs.total} perPage={repairs.perPage} currentPage={repairs.page} />
        </>
      )}

      {repairs && repairs.records.length < 1 && (
        <p className="lg range-center">No repairs found mathcing your query</p>
      )}
    </>
  );
};

export default Repairs;

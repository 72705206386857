import React from 'react';
import { useHistory } from 'react-router-dom';

import SearchBar from '../../components/SearchBar';
import { searchPurchases } from '../../services/purchases';

const QuickSearchCustomers = () => {
  const history = useHistory();

  return (
    <>
      <h1>Quick Search Purchases</h1>
      <p className="lg range-center">
        Use the search bar below to search for an existing purchase.
      </p>

      <SearchBar
        fieldOptions={[
          { value: 'invoiceNumber', label: 'Invoice Number' },
          { value: 'orderNumber', label: 'Order Number' },
          { value: 'description', label: 'Description' }
        ]}
        fetchResults={searchPurchases}
        renderSuggestion={(result, field) => {
          if (field === 'description') return <strong>{result.description}</strong>;
          return (
            <>
              <strong>{result[field]}</strong> - {result.description}
            </>
          );
        }}
        onSelect={purchase => history.push(`/purchases/${purchase._id}`)}
      />
    </>
  );
};

export default QuickSearchCustomers;

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

// Import global styles first:
import './scss/global.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://effa29318b7745cc8c5a33e6ab0b54e5@sentry.io/2293047' });
  console.log('Initialized Sentry');
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

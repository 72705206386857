import React from 'react';
import { Formik, Form, FieldArray, getIn } from 'formik';
import classnames from 'classnames';

import Button from '../Button';
import FormField from '../FormField';
import { Grid, Col } from '../Grid';
import { Tabs, TabList, Tab, TabPanel, TabPanels } from '../Tabs';
import SearchBar from '../SearchBar';
// import DebugPanel from '../DebugPanel';

import { searchPurchases } from '../../services/purchases';
import { STORES } from '../../config';

import validationSchema from './validationSchema';
import defaultValues from './defaultValues';

const RepairForm = ({ mode, initialValues = {}, onSubmit, auth }) => {
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ ...defaultValues, ...initialValues }}
      onSubmit={onSubmit}
      render={({ touched, errors, values, setFieldValue }) => {
        const hasError = (...fields) => {
          return fields.some(field => getIn(touched, field) && getIn(errors, field));
        };

        return (
          <Form>
            <Tabs>
              <TabList>
                <Tab error={hasError('itemType', 'purchase', 'itemDescription')}>Item Details</Tab>
                <Tab error={hasError('dateBooked', 'store', 'repairDescription', 'repairNumber')}>
                  Repair Details
                </Tab>
                <Tab error={hasError('freeOfCharge', 'reason', 'quotedCost', 'chargeableCost')}>
                  Cost
                </Tab>
                {mode === 'add' && (
                  <Tab error={hasError('notes')}>Notes ({values.notes.length})</Tab>
                )}
              </TabList>
              <TabPanels>
                <TabPanel>
                  {mode === 'add' && !values.itemType && (
                    <>
                      <p
                        className={classnames(
                          'range-center',
                          touched.itemType && errors.itemType && 'heavy danger'
                        )}
                      >
                        Please search for a purchase or enter the item details manually.
                      </p>
                      <p className="range-center">
                        <Button
                          format="secondary"
                          onClick={() => setFieldValue('itemType', 'purchase')}
                        >
                          Search Purchases
                        </Button>
                        <Button
                          format="secondary"
                          onClick={() => setFieldValue('itemType', 'manual')}
                        >
                          Enter Manually
                        </Button>
                      </p>
                    </>
                  )}

                  {values.itemType === 'purchase' && !values.purchase && (
                    <div style={{ width: 540, margin: '0 auto' }}>
                      <h4 className="range-center">Search Purchases</h4>
                      {touched.purchase && errors.purchase && (
                        <p className="range-center heavy danger">{errors.purchase}</p>
                      )}
                      <SearchBar
                        fieldOptions={[
                          { value: 'invoiceNumber', label: 'Invoice Number' },
                          { value: 'orderNumber', label: 'Order Number' },
                          { value: 'description', label: 'Description' }
                        ]}
                        fetchResults={searchPurchases}
                        renderSuggestion={(result, field) => {
                          if (field === 'description') return <strong>{result.description}</strong>;
                          return (
                            <>
                              <strong>{result[field]}</strong> - {result.description}
                            </>
                          );
                        }}
                        onSelect={purchase => {
                          setFieldValue('purchase', purchase);
                        }}
                      />
                      <p className="range-right">
                        <Button format="secondary" onClick={() => setFieldValue('itemType', '')}>
                          Back
                        </Button>
                      </p>
                    </div>
                  )}

                  {values.itemType === 'purchase' && values.purchase && (
                    <Grid gutters="md">
                      <Col width="12">
                        <strong>Item Description</strong>
                        <br />
                        {values.purchase.description}
                      </Col>
                      <Col width="6">
                        <strong>Invoice Number</strong>
                        <br />
                        {values.purchase.invoiceNumber}
                      </Col>
                    </Grid>
                  )}

                  {(mode === 'edit' || values.itemType === 'manual') && (
                    <div style={{ width: 540, margin: '0 auto' }}>
                      <h4 className="range-center">Enter Details Manually</h4>
                      <FormField type="textarea" name="itemDescription" label="Item Description" />
                      <p className="range-right">
                        <Button format="secondary" onClick={() => setFieldValue('itemType', '')}>
                          Back
                        </Button>
                      </p>
                    </div>
                  )}
                </TabPanel>
                <TabPanel>
                  <Grid gutters="md">
                    <Col width="12">
                      <FormField
                        type="textarea"
                        name="repairDescription"
                        label="Repair Description"
                      />
                    </Col>
                    <Col width="6">
                      <FormField
                        type="date"
                        name="dateBooked"
                        label="Date Booked"
                        max={new Date()}
                      />
                    </Col>
                    <Col width="6">
                      <FormField
                        type="select"
                        name="store"
                        id="store"
                        label="Store"
                        options={STORES}
                      />
                    </Col>
                    <Col width="6">
                      <FormField type="number" name="repairNumber" label="Repair Number" />
                    </Col>
                  </Grid>
                </TabPanel>
                <TabPanel>
                  <Grid gutters="md">
                    <Col width="6">
                      <FormField
                        type="radios"
                        name="freeOfCharge"
                        label="Free of Charge?"
                        options={[
                          { label: 'Yes', value: true },
                          { label: 'No', value: false }
                        ]}
                      />
                    </Col>
                    <Col width="6" />
                    {values.freeOfCharge === 'true' && (
                      <Col width="12">
                        <FormField type="textarea" name="freeOfChargeReaon" label="Reason" />
                      </Col>
                    )}
                    {values.freeOfCharge === 'false' && (
                      <>
                        <Col width="6">
                          <FormField type="currency" name="quotedCost" label="Quoted Cost" />
                        </Col>
                        <Col width="6">
                          <FormField
                            type="currency"
                            name="chargeableCost"
                            label="Chargeable Cost"
                          />
                        </Col>
                      </>
                    )}
                  </Grid>
                </TabPanel>

                {mode === 'add' && (
                  <TabPanel>
                    <FieldArray
                      name="notes"
                      id="notes"
                      render={arrayHelpers => (
                        <Grid gutters="md">
                          {values.notes.map((note, index) => (
                            <Col width="12" key={index}>
                              <Grid gutters="sm">
                                <Col>
                                  <FormField
                                    type="textarea"
                                    name={`notes.${index}.content`}
                                    label="Note"
                                  />
                                </Col>
                                <Col width="auto">
                                  <Button
                                    format="danger"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    Delete
                                  </Button>
                                </Col>
                              </Grid>
                            </Col>
                          ))}

                          {!values.notes.length && (
                            <Col width="12">
                              <p>No notes added to repair.</p>
                            </Col>
                          )}

                          <Col width="12">
                            <Button
                              type="button"
                              format="primary"
                              onClick={() =>
                                arrayHelpers.push({ content: '', createdBy: auth.user._id })
                              }
                            >
                              Add Note
                            </Button>
                          </Col>
                        </Grid>
                      )}
                    />
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>

            <p className="range-right">
              <Button type="submit">Add Repair</Button>
            </p>

            {/* <DebugPanel data={{ values, touched, errors }} /> */}
          </Form>
        );
      }}
    />
  );
};

export default RepairForm;

import * as Yup from 'yup';
import subYears from 'date-fns/subYears';
import startOfDay from 'date-fns/startOfDay';

import {
  PURCHASE_ITEM_TYPES,
  PURCHASE_PAYMENT_METHODS,
  PURCHASE_SALE_TYPES,
  VALIDATION_REQUIRED,
  STORES
} from '../../config';

export default function createValidationSchema(maxInsurableValue) {
  return Yup.object().shape({
    // Item details section
    description: Yup.string().required(VALIDATION_REQUIRED),
    itemType: Yup.string()
      .oneOf(PURCHASE_ITEM_TYPES)
      .required(VALIDATION_REQUIRED),
    fingerSize: Yup.string().when('itemType', {
      is: val => val && val.includes('Ring'),
      then: Yup.string().required(VALIDATION_REQUIRED)
    }),

    // Stock Details
    specialOrder: Yup.boolean().required(VALIDATION_REQUIRED),
    stockNumber: Yup.string().when('specialOrder', {
      is: false,
      then: Yup.string().required(VALIDATION_REQUIRED)
    }),
    orderNumber: Yup.string().when('itemType', {
      is: PURCHASE_ITEM_TYPES[1],
      then: Yup.string().required(VALIDATION_REQUIRED)
    }),

    // Sale details
    invoicePrice: Yup.number().required(VALIDATION_REQUIRED),
    rrp: Yup.number().required(VALIDATION_REQUIRED),
    invoiceNumber: Yup.string().required(VALIDATION_REQUIRED),
    paymentMethod: Yup.string()
      .oneOf(PURCHASE_PAYMENT_METHODS)
      .required(VALIDATION_REQUIRED),
    store: Yup.string()
      .oneOf(STORES)
      .required(VALIDATION_REQUIRED),
    servedBy: Yup.string().required(VALIDATION_REQUIRED),
    purchaseDate: Yup.date().required(VALIDATION_REQUIRED),
    saleType: Yup.string()
      .oneOf(PURCHASE_SALE_TYPES)
      .required(VALIDATION_REQUIRED),
    taxFree: Yup.boolean().when('saleType', {
      is: val => val && val !== 'Pre-owned',
      then: Yup.boolean().required(VALIDATION_REQUIRED)
    }),

    // Insurance
    insuranceTaken: Yup.boolean().when(['saleType', 'rrp'], {
      is: (saleType, rrp) => saleType === 'New' && rrp <= maxInsurableValue,
      then: Yup.boolean().required(VALIDATION_REQUIRED)
    }),
    wearerDob: Yup.date()
      .max(
        subYears(startOfDay(new Date()), 18),
        'Wearer must be over 18 years of age to be covered'
      )
      .when('insuranceTaken', {
        is: true,
        then: schema => schema.required(VALIDATION_REQUIRED)
      }),

    // Complimentary Gift
    complimentaryGift: Yup.boolean().required(VALIDATION_REQUIRED),
    complimentaryGiftDetails: Yup.string().when('complimentaryGift', {
      is: true,
      then: Yup.string().required(VALIDATION_REQUIRED)
    }),

    // Notes
    notes: Yup.array(
      Yup.object().shape({
        content: Yup.string().required(VALIDATION_REQUIRED)
      })
    )
  });
}

import React, { useState, useEffect } from 'react';

import { Grid, Col } from '../Grid';
import FormField from '../FormField';

import { PURCHASE_PAYMENT_METHODS, STORES, PURCHASE_SALE_TYPES } from '../../config';

import { fetchStaffMembersByStore } from '../../services/users';

const SaleDetails = ({ values }) => {
  // Load staff members when store changes
  const [staff, setStaff] = useState([]);

  useEffect(() => {
    fetchStaffMembersByStore(values.store)
      .then(res => setStaff(res))
      .catch(error => setStaff([]));
  }, [values.store]);

  return (
    <>
      <Grid gutters="md">
        <Col width="6">
          <FormField type="currency" name="invoicePrice" label="Invoice Price" />
        </Col>
        <Col width="6">
          <FormField type="currency" name="rrp" label="RRP" />
        </Col>
        <Col width="6">
          <FormField type="text" name="invoiceNumber" label="Invoice Number" />
        </Col>
        <Col width="6">
          <FormField
            type="select"
            name="paymentMethod"
            label="Payment Method"
            options={PURCHASE_PAYMENT_METHODS}
          />
        </Col>

        <Col width="6">
          <FormField type="select" name="store" label="Purchased From" options={STORES} />
        </Col>
        <Col width="6">
          <FormField
            type="select"
            name="servedBy"
            label="Served By"
            options={staff.map(sm => ({
              value: sm._id,
              label: `${sm.firstname} ${sm.lastname}`
            }))}
            disabled={!values.store}
            placeholder={!values.store ? 'Select a store first' : ''}
          />
        </Col>
        <Col width="6">
          <FormField type="date" name="purchaseDate" label="Purchase Date" max={new Date()} />
        </Col>
      </Grid>
      <br />
      <br />
      <Grid gutters="md">
        <Col width="6">
          <FormField
            type="select"
            name="saleType"
            label="Type of Sale"
            options={PURCHASE_SALE_TYPES}
          />
        </Col>
        <Col width="6">
          {values.saleType && values.saleType !== 'Pre-owned' && (
            <FormField
              type="radios"
              name="taxFree"
              label="Tax Free?"
              options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
            />
          )}
        </Col>
      </Grid>
    </>
  );
};

export default SaleDetails;

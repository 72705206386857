import * as Yup from 'yup';
import { STORES } from '../../config';

// Export the validation messages for use in tests
export const FIRSTNAME_REQUIRED = 'First name is required';
export const LASTNAME_REQUIRED = 'Last name is required';
export const EMAIL_REQUIRED = 'Email address is required';
export const EMAIL_INVALID = 'Please enter a valid email address';
export const GENDER_REQUIRED = 'Gender is required';
export const PHONE_REQUIRED = 'Phone number is required';
export const MOBILE_REQUIRED = 'Mobile number is required';
export const ADDRESS_STREET_1_REQUIRED = 'Address line 1 is required';
export const ADDRESS_CITY_REQUIRED = 'City is required';
export const ADDRESS_REGION_REQUIRED = 'Region is required';
export const ADDRESS_POSTCODE_REQUIRED = 'Postcode is required';
export const ADDRESS_COUNTRY_REQUIRED = 'Country is required';
export const ISVIP_REQUIRED = 'Is this customer a VIP member?';
export const VIP_NUMBER_REQUIRED = 'VIP Number is required';
export const GIFT_DESCRIPTION_REQUIRED = 'Please describe the free gift';
export const NOTE_CONTENT_REQUIRED = 'Note content is required';
export const SIGNUP_STORE_REQUIRED = 'Please select a store';

export default Yup.object().shape({
  // Customer details section
  firstname: Yup.string().required(FIRSTNAME_REQUIRED),
  lastname: Yup.string().required(LASTNAME_REQUIRED),
  email: Yup.string()
    .email(EMAIL_INVALID)
    .required(EMAIL_REQUIRED),
  gender: Yup.string()
    .oneOf(['Male', 'Female'])
    .required(GENDER_REQUIRED),
  phone: Yup.string().required(PHONE_REQUIRED),
  mobile: Yup.string().required(MOBILE_REQUIRED),

  // Address section
  address: Yup.object().shape({
    street_1: Yup.string().required(ADDRESS_STREET_1_REQUIRED),
    city: Yup.string().required(ADDRESS_CITY_REQUIRED),
    region: Yup.string().required(ADDRESS_REGION_REQUIRED),
    postcode: Yup.string().required(ADDRESS_POSTCODE_REQUIRED),
    country: Yup.string().required(ADDRESS_COUNTRY_REQUIRED)
  }),

  // VIP Membership section
  isVip: Yup.boolean().required(ISVIP_REQUIRED),
  vipNumber: Yup.string().when('isVip', {
    is: true,
    then: Yup.string().required(VIP_NUMBER_REQUIRED)
  }),

  // Free Gifts section
  freeGifts: Yup.array(
    Yup.object().shape({
      description: Yup.string().required(GIFT_DESCRIPTION_REQUIRED)
    })
  ),

  // Notes section
  notes: Yup.array(
    Yup.object().shape({
      content: Yup.string().required(NOTE_CONTENT_REQUIRED)
    })
  ),

  // Internal Section
  signupStore: Yup.string()
    .oneOf(STORES)
    .required(SIGNUP_STORE_REQUIRED)
});

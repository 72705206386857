import React from 'react';
import classnames from 'classnames';
import { Field } from 'formik';

import styles from './Select.module.scss';

const Select = ({ name, options, disabled = false, placeholder, className = null }) => {
  return (
    <Field
      className={classnames(styles.select, className)}
      component="select"
      name={name}
      id={name}
      disabled={disabled}
    >
      <option value="" disabled>
        {placeholder || 'Please select'}
      </option>
      {options.map(option =>
        typeof option === 'string' ? (
          <option key={option} value={option}>
            {option}
          </option>
        ) : (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        )
      )}
    </Field>
  );
};

export default Select;

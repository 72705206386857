import * as Yup from 'yup';

export const AUDIENCE_REQUIRED = 'Audience is required';
export const TITLE_REQUIRED = 'Title is required';
export const CONTENT_REQUIRED = 'Content is required';

export default Yup.object().shape({
  audience: Yup.array()
    .of(Yup.string().oneOf(['staff', 'assetsure']))
    .min(1, AUDIENCE_REQUIRED),
  title: Yup.string().required(TITLE_REQUIRED),
  content: Yup.string().required(CONTENT_REQUIRED)
});

import React, { useState, useEffect } from 'react';
import { getIn, withFormik } from 'formik';
import addYears from 'date-fns/addYears';

import { Tabs, TabList, Tab, TabPanels, TabPanel } from '../Tabs';
import ItemDetails from './ItemDetails';
import SaleDetails from './SaleDetails';
import Insurance from './Insurance';
import ComplimentaryGift from './ComplimentaryGift';
import PurchaseNotes from './PurchaseNotes';
import Button from '../Button';
// import DebugPanel from '../DebugPanel';

import defaultValues from './defaultValues';
import createValidationSchema from './validationSchema';

import { inputDate } from '../../utils/dates';

const PurchaseForm = ({
  maxInsurableValue,
  handleSubmit,
  setFieldValue,
  touched,
  errors,
  values,
  mode
}) => {
  // Maintain a list of invalid tabs
  const [invalidTabs, setInvalidTabs] = useState([]);
  // Check for existence of errors in each form section andd add/remove from the list of invalid tabs
  useEffect(() => {
    const invalidTabs = [];
    // Item Details Tab
    if (
      (touched.description && errors.description) ||
      (touched.itemType && errors.itemType) ||
      (touched.fingerSize && errors.fingerSize) ||
      (touched.specialOrder && errors.specialOrder) ||
      (touched.stockNumber && errors.stockNumber) ||
      (touched.orderNumber && errors.orderNumber)
    ) {
      invalidTabs.push('itemdetails');
    }

    // Sale Details Tab
    if (
      (touched.invoicePrice && errors.invoicePrice) ||
      (touched.rrp && errors.rrp) ||
      (touched.invoiceNumber && errors.invoiceNumber) ||
      (touched.paymentMethod && errors.paymentMethod) ||
      (touched.store && errors.store) ||
      (touched.servedBy && errors.servedBy) ||
      (touched.purchaseDate && errors.purchaseDate) ||
      (touched.saleType && errors.saleType) ||
      (touched.taxFree && errors.taxFree)
    ) {
      invalidTabs.push('saledetails');
    }

    if (touched.insuranceTaken && errors.insuranceTaken) {
      invalidTabs.push('insurance');
    }

    // Complimentarty Gift Tab
    if (
      (touched.complimentaryGift && errors.complimentaryGift) ||
      (touched.complimentaryGiftDetails && errors.complimentaryGiftDetails)
    ) {
      invalidTabs.push('gift');
    }

    // Notes Tab
    if (touched.notes && errors.notes) {
      invalidTabs.push('notes');
    }

    setInvalidTabs(invalidTabs);
  }, [errors, touched]);

  return (
    <form onSubmit={handleSubmit}>
      <Tabs>
        <TabList>
          <Tab error={invalidTabs.includes('itemdetails')}>Item Details</Tab>
          <Tab error={invalidTabs.includes('saledetails')}>Sale Details</Tab>
          <Tab error={invalidTabs.includes('insurance')}>Insurance</Tab>
          <Tab error={invalidTabs.includes('gift')}>Complimentary Gift</Tab>
          {mode === 'add' && (
            <Tab error={invalidTabs.includes('notes')}>Notes ({values.notes.length})</Tab>
          )}
        </TabList>
        <TabPanels>
          <TabPanel>
            <ItemDetails values={values} />
          </TabPanel>
          <TabPanel>
            <SaleDetails values={values} />
          </TabPanel>
          <TabPanel>
            <Insurance values={values} maxInsurableValue={maxInsurableValue} />
          </TabPanel>
          <TabPanel>
            <ComplimentaryGift values={values} />
          </TabPanel>
          {mode === 'add' && (
            <TabPanel>
              <PurchaseNotes values={values} />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
      <p className="range-right">
        <Button type="submit">Submit</Button>
      </p>

      {/* <DebugPanel data={{ values, touched, errors }} /> */}
    </form>
  );
};

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => ({
    ...defaultValues,
    ...initialValues,
    purchaseDate: inputDate(getIn(initialValues, 'purchaseDate', new Date())),
    wearerDob: inputDate(getIn(initialValues, 'wearerDob', null))
  }),
  validationSchema: props => createValidationSchema(props.maxInsurableValue),
  handleSubmit: (values, { props }) => {
    // Make changes to some values based on other fields.
    // The reason we do this on the submit event instead of in the form itself
    // is in case someone changes a field on the edit form and it clears/changes other fields.
    // We want them to be able to change it back and restore the changes if it was an accident.

    // Reset inurance fields
    if (values.rrp > props.maxInsurableValue || values.saleType !== 'New') {
      values.insuranceTaken = false;
      values.wearerDob = '';
    }

    // Reset taxFree field
    if (values.saleType === 'Pre-owned') {
      values.taxFree = false;
    }

    // Update the waranty details
    if (values.saleType === 'New') {
      values.warranty_check_1.dueDate = addYears(new Date(values.purchaseDate), 1);
      values.warranty_check_2.dueDate = addYears(new Date(values.purchaseDate), 2);
    }

    console.log('Values: ', values);

    // Submit the values!
    props.onSubmit(values);
  }
})(PurchaseForm);

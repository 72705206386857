import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import { Grid, Col } from '../Grid';

import styles from './Pagination.module.scss';

const Pagination = ({ total, perPage, currentPage }) => {
  const location = useLocation();

  const currentQuery = queryString.parse(location.search);

  if (perPage >= total) {
    return null;
  }

  const page = Number(currentPage);
  const numPages = Math.ceil(total / perPage);

  return (
    <Grid valign="center" className={styles.pagination}>
      <Col>
        {page > 1 && (
          <Link
            to={{
              path: location.pathname,
              search: queryString.stringify({ ...currentQuery, page: page - 1 })
            }}
            className={styles.button}
          >
            &laquo; Previous page
          </Link>
        )}
      </Col>
      <Col width="auto" className="range-center">
        {page === 1 ? page : (page - 1) * perPage + 1} to{' '}
        {page * perPage > total ? total : page * perPage} of {total}
      </Col>
      <Col className="range-right">
        {page < numPages && (
          <Link
            to={{
              path: location.pathname,
              search: queryString.stringify({ ...currentQuery, page: page + 1 })
            }}
            className={styles.button}
          >
            Next page &raquo;
          </Link>
        )}
      </Col>
    </Grid>
  );
};

export default Pagination;

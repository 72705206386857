import React from 'react';

import { Grid, Col } from '../Grid';
import FormField from '../FormField';

import { PURCHASE_ITEM_TYPES } from '../../config';

const ItemDetails = ({ values }) => (
  <Grid gutters="md">
    <Col width="12">
      <FormField type="textarea" name="description" label="Item Description" />
    </Col>
    <Col width="6">
      <FormField type="select" name="itemType" label="Item Type" options={PURCHASE_ITEM_TYPES} />
    </Col>
    <Col width="6">
      {values.itemType.includes('Ring') && (
        <FormField type="text" name="fingerSize" label="Finger Size" />
      )}
    </Col>
    <Col width="6">
      <FormField
        type="radios"
        name="specialOrder"
        label="Special Order"
        options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
      />
    </Col>
    <Col width="6" />
    <Col width="6">
      <FormField type="text" name="orderNumber" label="Order No." />
    </Col>
    <Col width="6">
      <FormField type="text" name="stockNumber" label="Stock No." />
    </Col>
  </Grid>
);

export default ItemDetails;

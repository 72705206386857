import React from 'react';
import classnames from 'classnames';
import { Field } from 'formik';

import styles from './Textarea.module.scss';

const Textarea = ({ name, rows = 3, disabled = false, className = null }) => {
  return (
    <Field
      className={classnames(styles.textarea, className)}
      component="textarea"
      name={name}
      id={name}
      disabled={disabled}
      rows={rows}
    />
  );
};

export default Textarea;

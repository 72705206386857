import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import styles from './FlashMessage.module.scss';

const FlashMessageContext = React.createContext();

export const FlashProvider = ({ children }) => {
  const [flash, setFlash] = useState(null);
  return (
    <FlashMessageContext.Provider value={{ flash, setFlash }}>
      {children}
    </FlashMessageContext.Provider>
  );
};

export const useFlash = () => {
  const { flash, setFlash } = React.useContext(FlashMessageContext);
  const history = useHistory();

  if (flash === undefined || setFlash === 'undefined') {
    throw new Error('useFlash must be used within a FlashProvider');
  }

  const showFlash = useCallback((type, content) => setFlash({ type, content }), [setFlash]);

  const dismissFlash = useCallback(() => setFlash(null), [setFlash]);

  const redirectWithFlash = useCallback(
    (path, type, content) => {
      history.push(path);
      setFlash({ type, content });
    },
    [history, setFlash]
  );

  return {
    showFlash,
    dismissFlash,
    redirectWithFlash,
    flash
  };
};

const FlashMessage = () => {
  const { flash, dismissFlash } = useFlash();

  useEffect(() => {
    if (flash) {
      const timeout = setTimeout(dismissFlash, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [flash, dismissFlash]);

  if (!flash) return null;

  return (
    <div
      role="alert"
      className={classnames(styles.flash, styles[flash.type])}
      onClick={dismissFlash}
    >
      {flash.content}
    </div>
  );
};

export default FlashMessage;

import React from 'react';

import { Grid, Col } from '../Grid';
import FormField from '../../components/FormField';

const ComplimentaryGift = ({ values }) => {
  return (
    <Grid gutters="md">
      <Col width="6">
        <FormField
          type="radios"
          name="complimentaryGift"
          label="Complimentary Gift?"
          options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
        />
      </Col>
      <Col width="12">
        {values.complimentaryGift === 'true' && (
          <FormField type="textarea" name="complimentaryGiftDetails" label="Gift Details" />
        )}
      </Col>
    </Grid>
  );
};

export default ComplimentaryGift;

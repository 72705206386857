import axios from 'axios';

export const fetchNotificationsByStatus = status =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/notifications/${status}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const fetchNotificationById = id =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/notifications/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const addNotification = values =>
  new Promise((resolve, reject) => {
    axios
      .post('/api/notifications', values)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const updateNotification = (id, changes) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/api/notifications/${id}`, changes)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const markNotificationAsRead = notificationId =>
  new Promise((resolve, reject) => {
    axios
      .post(`/api/notifications/${notificationId}/read`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const deleteNotification = id =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/api/notifications/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

// Components
import { Grid, Col } from '../../components/Grid';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Filters from '../../components/Filters';
import Pagination from '../../components/Pagination';
import ResultsTable from '../../components/ResultsTable';

// Services, Config etc.
import { fetchUsers } from '../../services/users';
import { USER_ROLES } from '../../config';

const Users = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [showFilters, setShowFilters] = useState(false);
  const [users, setUsers] = useState(null);

  // Perform request whenever the query string changes
  useEffect(() => {
    const defaultQuery = { sortBy: 'createdAt', order: 'asc', role: [], disabled: false, page: 1 };
    const currentQuery = queryString.parse(search);

    fetchUsers({
      ...defaultQuery,
      ...currentQuery
    }).then(setUsers);
  }, [search]);

  return (
    <>
      <Grid>
        <Col>
          <Button format="secondary" onClick={() => setShowFilters(true)}>
            <Icon icon="filter" /> Show filters
          </Button>
        </Col>
        <Col width="auto">
          <h1>Users</h1>
        </Col>
        <Col className="range-right">
          <Button format="primary" href="/users/add">
            + Add User
          </Button>
        </Col>
      </Grid>

      <Filters
        open={showFilters}
        onClose={() => setShowFilters(false)}
        options={[
          {
            label: 'Role',
            name: 'role',
            type: 'multiple',
            options: Object.entries(USER_ROLES).map(([key, value]) => ({
              label: value,
              value: key
            }))
          },
          {
            label: 'Disabled',
            name: 'disabled',
            type: 'single',
            options: [{ label: 'No', value: false }, { label: 'Yes', value: true }],
            defaultValue: 'false'
          }
        ]}
      />

      {users && users.records.length > 0 && (
        <>
          <ResultsTable
            config={{
              columns: [
                {
                  key: 'username',
                  label: 'Username',
                  sortable: true
                },
                {
                  key: 'firstname',
                  label: 'First Name',
                  sortable: true
                },
                {
                  key: 'lastname',
                  label: 'Last Name',
                  sortable: true
                },
                {
                  key: 'role',
                  label: 'Role',
                  format: 'role'
                },
                {
                  key: 'createdAt',
                  label: 'Date Added',
                  sortable: true,
                  format: 'date'
                },
                {
                  key: 'disabled',
                  label: 'Disabled',
                  sortable: false,
                  format: 'boolean'
                }
              ],
              onRowClick: _id => history.push(`/users/${_id}`)
            }}
            rows={users.records}
          />
          <Pagination total={users.total} perPage={users.perPage} currentPage={users.page} />
        </>
      )}

      {users && users.records.length < 1 && (
        <p className="lg range-center">No users found mathcing your query</p>
      )}
    </>
  );
};

export default Users;

/**
 * Dynamically create the initialValues for the filters form based on the filter options and current query params.
 * If there is a filter alrady in the query params, the initial value is set to that, otherwise it uses the default value or empty
 * @param {array} options the filter options
 * @param {object} currentQuery the current query params
 */
export default function buildInitialValues(options, currentQuery) {
  const initialValues = {};
  options.forEach(option => {
    if (option.type === 'multiple') {
      // params can be parsed as a string or array depending if there are multiple.
      // The initialValue for array based fields MUST  be an array. So here we force them into an array either way.
      initialValues[option.name] = currentQuery[option.name]
        ? [].concat(currentQuery[option.name])
        : option.defaultValue || [];
    } else {
      initialValues[option.name] = currentQuery[option.name] || option.defaultValue || '';
    }
  });
  return initialValues;
}

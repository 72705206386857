import React from 'react';

import { Grid, Col } from '../Grid';
import FormField from '../FormField';

import { money } from '../../utils/money';

const Insurance = ({ values, maxInsurableValue }) => {
  // MIssing fields that this depends on
  if (!values.saleType || !values.rrp) {
    return (
      <>
        <p className="lg">Please complete the following fields first:</p>
        <ul className="bullets">
          {!values.saleType && (
            <li>
              <strong>&lsquo;Sale Type&rsquo;</strong> in the &lsquo;Sale Details&rsquo; tab
            </li>
          )}
          {!values.rrp && (
            <li>
              <strong>&lsquo;RRP&rsquo;</strong> in the &lsquo;Sale Details&rsquo; tab
            </li>
          )}
        </ul>
      </>
    );
  }

  // Not eligible for complimentary insurance
  const isEligibleForInsurance = values.saleType === 'New' && values.rrp <= maxInsurableValue;
  if (!isEligibleForInsurance) {
    return (
      <>
        <p className="lg">This purchase is not eligible for the complimentary insurance cover.</p>
        <ul>
          {values.saleType !== 'New' && <li>This is a {values.saleType} item</li>}
          {values.rrp > maxInsurableValue && (
            <li>
              This items RRP is above the maximum insurable value of {money(maxInsurableValue)}
            </li>
          )}
        </ul>
      </>
    );
  }

  return (
    <Grid gutters="md">
      <Col width="6">
        <FormField
          type="radios"
          name="insuranceTaken"
          label="Insurance?"
          options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
        />
      </Col>
      <Col width="6">
        {values.insuranceTaken.toString() === 'true' && (
          <FormField type="date" name="wearerDob" label="Wearer DOB" max={new Date()} />
        )}
      </Col>
    </Grid>
  );
};
export default Insurance;

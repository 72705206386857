import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, NavLink } from 'react-router-dom';

import { Grid, Col } from '../Grid';
import Logo from '../Logo';
import styles from './Header.module.scss';

import { logout } from '../../services/auth';
import { fetchNotificationsByStatus } from '../../services/notifications';

const Header = ({ auth }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [notificationCount, setNotificationCount] = useState(0);

  const { user } = auth;

  // refetch notifications on each route change
  useEffect(() => {
    if (user.role !== 'admin' && user.role !== 'ho') {
      fetchNotificationsByStatus('unread')
        .then(notifications => setNotificationCount(notifications.length))
        .catch(() => setNotificationCount(0));
    }
  }, [pathname, user]);

  const handleLogoutClick = () => {
    logout();
    history.push('/login');
  };

  return (
    <header className={styles.header}>
      <Grid valign="center">
        <Col width="auto">
          <Logo small />
        </Col>
        <Col>
          <nav className={styles.menu}>
            <ul>
              <li>
                <NavLink to="/" activeClassName={styles.current} exact>
                  Dashboard
                </NavLink>
              </li>
              {user.role === 'admin' && (
                <>
                  <li>
                    <NavLink to="/settings" activeClassName={styles.current}>
                      Settings
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/users" activeClassName={styles.current}>
                      Users
                    </NavLink>
                  </li>
                </>
              )}
              {user.role !== 'assetsure' && (
                <>
                  <li>
                    <NavLink to="/customers" activeClassName={styles.current}>
                      Customers
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/purchases" activeClassName={styles.current}>
                      Purchases
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/repairs" activeClassName={styles.current}>
                      Repairs
                    </NavLink>
                  </li>
                </>
              )}
              {user.role === 'assetsure' && (
                <li>
                  <NavLink to="/uninsured-purchases" activeClassName={styles.current}>
                    Purchases
                  </NavLink>
                </li>
              )}

              <li>
                <NavLink
                  to={`/notifications/${['admin', 'ho'].includes(user.role) ? 'draft' : 'unread'}`}
                  isActive={(match, location) => location.pathname.includes('notifications')}
                  activeClassName={styles.current}
                >
                  Notifications
                  {notificationCount > 0 && (
                    <span className={styles.counter}>{notificationCount}</span>
                  )}
                </NavLink>
              </li>

              <li>
                <button type="button" onClick={handleLogoutClick}>
                  Logout
                </button>
              </li>
            </ul>
          </nav>
        </Col>
      </Grid>
    </header>
  );
};

export default Header;

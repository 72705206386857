import * as Yup from 'yup';
import { USER_ROLES, STORES } from '../../config';

// Export validation errors for use in tests
export const FIRSTNAME_ERROR = 'First name is required';
export const LASTNAME_ERROR = 'Last name is required';
export const USERNAME_ERROR = 'Username is required';
export const ROLE_ERROR = 'User role is required';
export const STORE_ERROR = "Store is required for users with 'Staff' role";

export default mode => {
  const schema = Yup.object().shape({
    firstname: Yup.string().required(FIRSTNAME_ERROR),
    lastname: Yup.string().required(LASTNAME_ERROR),
    role: Yup.string()
      .oneOf(Object.keys(USER_ROLES))
      .required(ROLE_ERROR),
    store: Yup.string().when('role', {
      is: 'staff',
      then: Yup.string()
        .oneOf(STORES)
        .required(STORE_ERROR)
    }),
    username: Yup.string().required(USERNAME_ERROR)
  });

  // Add password validation for add user form
  if (mode === 'add') {
    return schema.shape({
      password: Yup.string()
        .matches(/[A-Z]/) // contains uppercase letter
        .matches(/[a-z]/) // contains lowercase letter
        .matches(/[^a-zA-Z\d\s:]/) // contains a symbol
        .matches(/\d/) // contains a number
        .min(8) // is at least 8 characters
        .required()
    });
  }

  return schema;
};

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

// Components
import { Grid, Col } from '../../components/Grid';

// Services, Config etc.
import { fetchRepairById } from '../../services/repairs';
import RepairForm from '../../components/RepairForm';
import { inputDate } from '../../utils/dates';

const EditRepair = ({ auth }) => {
  const { repairId } = useParams();
  const [repair, setRepair] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchRepairById(repairId)
      .then(repair => {
        setRepair(repair);
        setError(null);
      })
      .catch(error => {
        setError(error);
        setRepair(null);
      });
  }, [repairId]);

  const handleSubmit = values => {
    console.log('Update repair with values: ', values);
  };

  return (
    <>
      <Grid>
        <Col>
          <Link to="/repairs">&laquo; Back to Repairs</Link>
        </Col>
        <Col width="auto">
          <h1>Edit Repair</h1>
        </Col>
        <Col className="range-right"></Col>
      </Grid>

      {error && <p className="lg range-center error">{error}</p>}

      {repair && (
        <RepairForm
          mode="edit"
          initialValues={{ ...repair, dateBooked: inputDate(repair.dateBooked) }}
          onSubmit={handleSubmit}
          auth={auth}
        />
      )}
    </>
  );
};

export default EditRepair;

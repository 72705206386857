import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

// Components
import { Grid, Col } from '../../components/Grid';
import CustomerForm from '../../components/CustomerForm';

// Misc
import { fetchCustomerById, updateCustomer } from '../../services/customers';
import { useFlash } from '../../components/FlashMessage';

const EditCustomer = ({ auth }) => {
  const { customerId } = useParams();
  const { redirectWithFlash, showFlash } = useFlash();
  const [customer, setCustomer] = useState(null);
  const [error, setError] = useState(null);

  // Fetch the customer
  useEffect(() => {
    fetchCustomerById(customerId)
      .then(setCustomer)
      .catch(error => {
        setError(error);
      });
  }, [customerId]);

  const handleSubmit = values => {
    updateCustomer(customerId, values)
      .then(() => {
        redirectWithFlash('/customers', 'success', 'Customer updated!');
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  const canEditCustomer =
    customer &&
    (auth.user.role === 'ho' ||
      auth.user.role === 'admin' ||
      customer.signupStore === auth.user.store ||
      customer.purchases.some(purchase => purchase.store === auth.user.store));

  if (customer && !canEditCustomer) {
    redirectWithFlash('/', 'error', 'You are not able to edit that customer!');
  }

  return (
    <>
      <Grid>
        <Col>
          <Link to="/customers">&laquo; Back to Customers</Link>
        </Col>
        <Col width="auto">
          <h1>Edit Customer</h1>
        </Col>
        <Col></Col>
      </Grid>

      {error && <p className="lg range-center error">{error}</p>}
      {customer && <CustomerForm mode="edit" initialValues={customer} onSubmit={handleSubmit} />}
    </>
  );
};

export default EditCustomer;

import React from 'react';
import { Formik, Form } from 'formik';

import Button from '../Button';
import { Grid, Col } from '../Grid';
import FormField from '../FormField';

import validationSchema from './validationSchema';

const SettingsForm = ({ onSubmit, initialValues }) => {
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      <Form noValidate autoComplete="off">
        <Grid gutters="md">
          <Col width="6">
            <FormField
              type="email"
              name="assetsureNotificationEmail"
              id="assetsureNotificationEmail"
              label="Assetsure email"
            />
          </Col>
          <Col width="6">
            <FormField
              type="email"
              name="adminNotificationEmail"
              id="adminNotificationEmail"
              label="Admin email"
            />
          </Col>
          <Col width="6">
            <FormField
              type="number"
              name="maxInsurableValue"
              id="maxInsurableValue"
              label="Max insurable value"
            />
          </Col>
          <Col width="12" className="range-right">
            <Button type="submit">Save settings</Button>
          </Col>
        </Grid>
      </Form>
    </Formik>
  );
};

export default SettingsForm;

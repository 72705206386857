import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

// Components
import { Grid, Col } from '../../components/Grid';
import PurchaseForm from '../../components/PurchaseForm';

// Misc
import { fetchPurchaseById, updatePurchase } from '../../services/purchases';
import { fetchSettings } from '../../services/settings';
import { useFlash } from '../../components/FlashMessage';

import styles from './Purchase.module.scss';

const EditPurchase = () => {
  const { purchaseId } = useParams();
  const { redirectWithFlash, showFlash } = useFlash();
  const [purchase, setPurchase] = useState(null);
  const [settings, setSettings] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the purchase
    fetchPurchaseById(purchaseId)
      .then(setPurchase)
      .catch(error => {
        setError(error);
      });

    // Fetch the settings
    fetchSettings(purchaseId)
      .then(setSettings)
      .catch(error => {
        setError(error);
      });
  }, [purchaseId]);

  const handleSubmit = values => {
    updatePurchase(purchaseId, values)
      .then(() => {
        redirectWithFlash('/purchases', 'success', 'Purchase updated!');
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  return (
    <>
      <Grid>
        <Col>
          <Link to="/purchases">&laquo; Back to Purchases</Link>
        </Col>
        <Col width="auto">
          <h1>Edit Purchase</h1>
        </Col>
        <Col></Col>
      </Grid>

      {error && <p className="lg range-center error">{error}</p>}

      {settings && purchase && (
        <>
          {purchase.issue && (
            <div className={styles['purchase-issue']}>
              <p>
                <strong>Issue reported by Assetsure:</strong>
              </p>
              <p>{purchase.issue}</p>
            </div>
          )}
          <PurchaseForm
            mode="edit"
            initialValues={purchase}
            onSubmit={handleSubmit}
            maxInsurableValue={settings.maxInsurableValue}
          />
        </>
      )}
    </>
  );
};

export default EditPurchase;

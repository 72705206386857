import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { getIn } from 'formik';
import classnames from 'classnames';

import { displayDate } from '../../utils/dates';
import { money } from '../../utils/money';
import { USER_ROLES } from '../../config';

import styles from './ResultsTable.module.scss';

const ResultsTable = ({ config, rows }) => {
  const location = useLocation();
  const history = useHistory();

  const currentQuery = queryString.parse(location.search);

  const changSorting = key => {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        ...currentQuery,
        sortBy: key,
        order: currentQuery.sortBy !== key ? 'desc' : currentQuery.order === 'asc' ? 'desc' : 'asc',
        page: 1
      })
    });
  };

  const formatCellContent = (value, result, format) => {
    if (typeof format === 'function') {
      return format(result);
    }
    if (!value && value !== false) {
      return <span className="muted">-</span>;
    }
    switch (format) {
      case 'date':
        return displayDate(new Date(value));
      case 'boolean':
        return value ? 'Yes' : 'No';
      case 'role':
        return USER_ROLES[value];
      case 'money':
        return money(value);
      case 'customer':
        return `${value.firstname} ${value.lastname}`;
      default:
        return value;
    }
  };

  return (
    <table className={styles.results}>
      <thead>
        <tr>
          {config.columns.map(column => (
            <th
              key={column.key}
              onClick={column.sortable ? () => changSorting(column.key) : null}
              className={classnames(
                column.sortable && styles.sortable,
                currentQuery.sortBy === column.key && styles[currentQuery.order],
                column.className
              )}
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map(result => (
          <tr key={result._id} onClick={() => config.onRowClick(result._id)}>
            {config.columns.map(column => (
              <td key={column.key} className={classnames(column.className)}>
                {formatCellContent(getIn(result, column.key), result, column.format)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ResultsTable;

import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Grid, Col } from '../../components/Grid';
import CustomerForm from '../../components/CustomerForm';

// Misc
import { addCustomer } from '../../services/customers';
import { useFlash } from '../../components/FlashMessage';

const AddCustomerPrePurchases = ({ auth }) => {
  const { redirectWithFlash, showFlash } = useFlash();

  const handleSubmit = values => {
    addCustomer(values)
      .then(newCustomer => {
        redirectWithFlash(`/purchases/add/${newCustomer._id}`, 'success', 'Customer added!');
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  return (
    <>
      <Grid>
        <Col>
          <Link to="/purchases/add">&laquo; Back</Link>
        </Col>
        <Col width="auto">
          <h1>Add Purchase</h1>
        </Col>
        <Col></Col>
      </Grid>

      <p className="lg range-center">
        Add a new customer using the form below then you can add a purchase.
      </p>
      <br />

      <CustomerForm
        onSubmit={handleSubmit}
        initialValues={auth.user.role === 'staff' ? { signupStore: auth.user.store } : {}}
      />
    </>
  );
};

export default AddCustomerPrePurchases;

import React from 'react';
import classnames from 'classnames';

import styles from './Grid.module.scss';

const Grid = ({
  gutters = null,
  align = null,
  valign = null,
  className = null,
  children,
  ...rest
}) => {
  const classes = classnames([
    styles.grid,
    styles[`grid--gutters-${gutters}`],
    styles[`grid--h-${align}`],
    styles[`grid--v-${valign}`],
    className
  ]);
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default Grid;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Grid, Col } from '../Grid';
import Button from '../Button';
import MultilineText from '../MultilineText';
import Modal from '../Modal';

import { USER_ROLES } from '../../config';
import { displayDate } from '../../utils/dates';

import styles from './Notification.module.scss';

const Notification = ({ notification, auth, onRead }) => {
  const [showReadByModal, setShowReadByModal] = useState(false);

  const {
    _id,
    title,
    status,
    publishDate,
    audience,
    createdBy,
    createdAt,
    readBy,
    content
  } = notification;

  const canManageNotifications = ['admin', 'ho'].includes(auth.user.role);
  const isRead = !readBy.find(user => user._id === auth.user._id);

  return (
    <>
      <article className={styles.notification}>
        <Grid gutters="sm">
          <Col>
            <h3 className={styles.title}>{title}</h3>
          </Col>
          <Col width="auto">
            {canManageNotifications ? (
              <Button format="secondary" href={`/notifications/${_id}`}>
                Edit
              </Button>
            ) : (
              isRead && (
                <Button format="secondary" onClick={() => onRead(_id)}>
                  Mark as read
                </Button>
              )
            )}
          </Col>
          <Col width="12">
            <p className={styles.meta}>
              {canManageNotifications && (
                <>
                  <span>
                    <strong>Audience:</strong> {audience.map(role => USER_ROLES[role]).join(', ')}
                  </span>
                  {status === 'published' && (
                    <span>
                      <strong>Read by: </strong>
                      {readBy.length > 0 ? (
                        <button
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer'
                          }}
                          onClick={() => setShowReadByModal(true)}
                        >
                          {readBy.length} user{readBy.length !== 1 && 's'}
                        </button>
                      ) : (
                        '0 users'
                      )}
                    </span>
                  )}
                </>
              )}
              <span>
                <strong>Created by:</strong> {createdBy.firstname} {createdBy.lastname}
              </span>
              {canManageNotifications && (
                <span>
                  <strong>Date created:</strong> {displayDate(createdAt)}
                </span>
              )}
              {status !== 'draft' && (
                <span>
                  <strong>Date published:</strong> {displayDate(publishDate)}
                </span>
              )}
            </p>
            <div className="lg">
              <MultilineText text={content} />
            </div>
          </Col>
        </Grid>
      </article>

      {readBy.length > 0 && (
        <Modal isOpen={showReadByModal} onClose={() => setShowReadByModal(false)}>
          <h4 className="primary">Notification Read By:</h4>
          <ul className={styles.readby}>
            {readBy.map(user => (
              <li key={user._id}>
                <strong>
                  {user.firstname} {user.lastname}
                </strong>{' '}
                - {user.role === 'assetsure' ? 'Assetsure' : user.store}
              </li>
            ))}
          </ul>
        </Modal>
      )}
    </>
  );
};

Notification.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
  }).isRequired
};

export default Notification;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import Button from '../Button';
import { displayDate } from '../../utils/dates';

import styles from './NotesList.module.scss';
import AddNoteModal from '../AddNoteModal';

const NotesList = ({ notes, addNote, deleteNote, user, noNotesMessage }) => {
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);

  const canDeleteNote = noteId => ['admin', 'ho'].includes(user.role) || user._id === noteId;

  return (
    <>
      {!notes.length ? (
        <p>{noNotesMessage}</p>
      ) : (
        <table className={styles.notes}>
          <thead>
            <tr>
              <th>Note</th>
              <th className={styles.date}>Date Added</th>
              <th className={styles.addedby}>Added By</th>
              <th className={styles.deleteCol}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {notes.map(note => (
              <tr key={note._id}>
                <td>{note.content}</td>
                <td className={styles.date}>{displayDate(note.createdAt)}</td>
                <td className={styles.addedby}>
                  {note.createdBy && `${note.createdBy.firstname} ${note.createdBy.lastname}`}
                </td>
                <th className={styles.deleteCol}>
                  {canDeleteNote(note._id) && (
                    <button
                      type="button"
                      className={styles.deleteBtn}
                      onClick={() => deleteNote(note._id)}
                    >
                      <Icon icon="trash" className="lg" />
                    </button>
                  )}
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Button type="button" onClick={() => setShowAddNoteModal(true)}>
        + Add Note
      </Button>
      <AddNoteModal
        isOpen={showAddNoteModal}
        onSubmit={values => {
          setShowAddNoteModal(false);
          addNote(values);
        }}
        onClose={() => setShowAddNoteModal(false)}
      />
    </>
  );
};

NotesList.propTypes = {
  noNotesMessage: PropTypes.string.isRequired,
  notes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteNote: PropTypes.func.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
  })
};

export default NotesList;

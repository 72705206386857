import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { FlashProvider } from './components/FlashMessage';
import AuthRoute from './components/AuthRoute';

// Users pages
import Users from './pages/Users';
import AddUser from './pages/Users/AddUser';
import EditUser from './pages/Users/EditUser';

// Notifications pages
import Notifications from './pages/Notifications';
import AddNotification from './pages/Notifications/AddNotification';
import EditNotification from './pages/Notifications/EditNotification';

// Customers pages
import Customers from './pages/Customers';
import ViewCustomer from './pages/Customers/ViewCustomer';
import AddCustomer from './pages/Customers/AddCustomer';
import EditCustomer from './pages/Customers/EditCustomer';

// Purchases pages
import Purchases from './pages/Purchases';
import AddPurchase from './pages/Purchases/AddPurchase';
import AddCustomerPrePurchase from './pages/Purchases/AddCustomerPrePurchase';
import AddPurchaseSearchCustomers from './pages/Purchases/AddPurchaseSearchCustomers';
import AddPurchaseToCustomer from './pages/Purchases/AddPurchaseToCustomer';
import EditPurchase from './pages/Purchases/EditPurchase';
import Purchase from './pages/Purchases/Purchase';
import UninsuredPurchases from './pages/Purchases/UninsuredPurchases';

// Warranties Pages
import Warranties from './pages/Warranties';

// Repairs Pages
import Repairs from './pages/Repairs';
import AddRepairPage from './pages/Repairs/AddRepair';
import Repair from './pages/Repairs/Repair';
import EditRepair from './pages/Repairs/EditRepair';

// Other pages
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import Login from './pages/Login';
import QuickSearchIndex from './pages/QuickSearch';
import QuickSearchCustomers from './pages/QuickSearch/Customers';
import QuickSearchPurchases from './pages/QuickSearch/Purchases';

const App = () => (
  <BrowserRouter>
    <FlashProvider>
      <Switch>
        <AuthRoute path="/" exact centered dark>
          <Dashboard />
        </AuthRoute>

        {/* Quick Search */}
        <AuthRoute path="/quick-search" exact roles={['admin', 'ho', 'staff']} dark centered>
          <QuickSearchIndex />
        </AuthRoute>
        <AuthRoute
          path="/quick-search/customers"
          exact
          roles={['admin', 'ho', 'staff']}
          dark
          centered
        >
          <QuickSearchCustomers />
        </AuthRoute>
        <AuthRoute
          path="/quick-search/purchases"
          exact
          roles={['admin', 'ho', 'staff']}
          dark
          centered
        >
          <QuickSearchPurchases />
        </AuthRoute>

        {/* Settings */}
        <AuthRoute path="/settings" exact roles="admin">
          <Settings />
        </AuthRoute>

        {/* Notifications Routes */}
        <AuthRoute path="/notifications/:status(draft|published)" roles={['admin', 'ho']} exact>
          <Notifications />
        </AuthRoute>
        <AuthRoute path="/notifications/:status(unread|read)" roles={['staff', 'assetsure']} exact>
          <Notifications />
        </AuthRoute>
        <AuthRoute path="/notifications/add" roles={['admin', 'ho']} exact>
          <AddNotification />
        </AuthRoute>
        <AuthRoute path="/notifications/:notificationId" roles={['admin', 'ho']} exact>
          <EditNotification />
        </AuthRoute>

        {/* Users routes */}
        <AuthRoute path="/users" exact roles="admin">
          <Users />
        </AuthRoute>
        <AuthRoute path="/users/add" exact roles="admin">
          <AddUser />
        </AuthRoute>
        <AuthRoute path="/users/:userId" exact roles="admin">
          <EditUser />
        </AuthRoute>

        {/* Customers routes */}
        <AuthRoute path="/customers" exact roles={['admin', 'ho', 'staff']}>
          <Customers />
        </AuthRoute>
        <AuthRoute path="/customers/add" exact roles={['admin', 'ho', 'staff']}>
          <AddCustomer />
        </AuthRoute>
        <AuthRoute path="/customers/:customerId" exact roles={['admin', 'ho', 'staff']}>
          <ViewCustomer />
        </AuthRoute>
        <AuthRoute path="/customers/:customerId/edit" exact roles={['admin', 'ho', 'staff']}>
          <EditCustomer />
        </AuthRoute>

        {/* Purchases routes */}
        <AuthRoute path="/purchases" exact roles={['admin', 'ho', 'staff']}>
          <Purchases />
        </AuthRoute>
        <AuthRoute path="/purchases/add" exact roles={['admin', 'ho', 'staff']}>
          <AddPurchase />
        </AuthRoute>
        <AuthRoute path="/purchases/add/existing-customer" exact roles={['admin', 'ho', 'staff']}>
          <AddPurchaseSearchCustomers />
        </AuthRoute>
        <AuthRoute path="/purchases/add/new-customer" exact roles={['admin', 'ho', 'staff']}>
          <AddCustomerPrePurchase />
        </AuthRoute>
        <AuthRoute path="/purchases/add/:customerId" exact roles={['admin', 'ho', 'staff']}>
          <AddPurchaseToCustomer />
        </AuthRoute>
        <AuthRoute path="/purchases/:purchaseId" exact>
          <Purchase />
        </AuthRoute>
        <AuthRoute path="/purchases/:purchaseId/edit" exact roles={['admin', 'ho']}>
          <EditPurchase />
        </AuthRoute>
        <AuthRoute path="/uninsured-purchases" exact roles="assetsure">
          <UninsuredPurchases />
        </AuthRoute>

        {/* Warranty routes */}
        <AuthRoute path="/warranties" exact roles={['admin', 'ho', 'staff']}>
          <Warranties />
        </AuthRoute>

        {/* Repairs routes */}
        <AuthRoute path="/repairs" exact roles={['admin', 'ho', 'staff']}>
          <Repairs />
        </AuthRoute>
        <AuthRoute path="/repairs/add" exact roles={['admin', 'ho', 'staff']}>
          <AddRepairPage />
        </AuthRoute>
        <AuthRoute path="/repairs/:repairId" exact roles={['admin', 'ho', 'staff']}>
          <Repair />
        </AuthRoute>
        <AuthRoute path="/repairs/:repairId/edit" exact roles={['admin', 'ho']}>
          <EditRepair />
        </AuthRoute>

        <Route path="/login">
          <Login />
        </Route>
      </Switch>
    </FlashProvider>
  </BrowserRouter>
);

export default App;

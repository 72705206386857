import React, { Fragment } from 'react';

const MultilineText = ({ text }) =>
  // Split by double line break create a <p>
  text.split('\n\n').map((paragraph, index) => (
    <p key={`p-${index}`}>
      {// Split by single line break and add line + <br>
      paragraph.split('\n').map((line, index) => (
        <Fragment key={`l-${index}`}>
          {index > 0 && <br />}
          {line}
        </Fragment>
      ))}
    </p>
  ));

export default MultilineText;

import React from 'react';
import { Formik, Form } from 'formik';

import Modal from '../Modal';
import FormField from '../FormField';
import Button from '../Button';

import initialValues from './initialValues';
import validationSchema from './validationSchema';

import styles from './AddNoteModal.module.scss';

const AddNoteModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form>
          <FormField type="textarea" name="content" id="content" label="Note" />
          <p className="range-right">
            <Button format="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </p>
        </Form>
      </Formik>
    </Modal>
  );
};

export default AddNoteModal;

import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

// Components
import { useFlash } from '../../components/FlashMessage';
import { Grid, Col } from '../../components/Grid';
import Button from '../../components/Button';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '../../components/Tabs';
import NotesList from '../../components/NotesList';

// Misc
import { fetchCustomerById, deleteCustomerNote, addCustomerNote } from '../../services/customers';
import { displayDate } from '../../utils/dates';
import { money } from '../../utils/money';

import styles from './ViewCustomer.module.scss';

const ViewCustomer = ({ auth }) => {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [error, setError] = useState(null);
  const { showFlash } = useFlash();

  const fetchCustomer = () => {
    fetchCustomerById(customerId)
      .then(setCustomer)
      .catch(error => {
        setError(error);
      });
  };

  // Fetch the customer
  useEffect(fetchCustomer, [customerId]);

  const canSeeFullCustomer =
    customer &&
    (auth.user.role === 'ho' ||
      auth.user.role === 'admin' ||
      customer.signupStore === auth.user.store ||
      customer.purchases.some(purchase => purchase.store === auth.user.store));

  const addNote = note => {
    addCustomerNote(customerId, note)
      .then(() => {
        fetchCustomer();
        showFlash('success', 'Note added');
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  const deleteNote = noteId => {
    deleteCustomerNote(customerId, noteId)
      .then(() => {
        fetchCustomer();
        showFlash('success', 'Note deleted');
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  return (
    <>
      <Grid>
        <Col>
          <Link to="/customers">&laquo; Back to Customers</Link>
        </Col>
        <Col width="auto">
          <h1>Customer</h1>
        </Col>
        <Col className="range-right">
          {customer && canSeeFullCustomer && (
            <Button href={`/customers/${customerId}/edit`}>Edit Customer</Button>
          )}
          {customer && <Button href={`/purchases/add/${customerId}`}>+ Add Purchase</Button>}
        </Col>
      </Grid>

      {error && <p className="lg range-center error">{error}</p>}

      {customer && (
        <Tabs>
          <TabList>
            <Tab>Customer Details</Tab>
            {customer.address && <Tab>Customer Address</Tab>}
            <Tab>VIP Membership</Tab>
            {customer.freeGifts && <Tab>Free Gifts ({customer.freeGifts.length})</Tab>}
            {customer.notes && <Tab>Notes ({customer.notes.length})</Tab>}
            <Tab>Internal</Tab>
            <Tab>Purchases ({customer.purchases.length})</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Grid gutters="md">
                <Col width="6">
                  <p>
                    <strong>First Name</strong>
                    <br /> {customer.firstname}
                  </p>
                </Col>
                <Col width="6">
                  <p>
                    <strong>Last Name</strong>
                    <br /> {customer.lastname}
                  </p>
                </Col>
                {canSeeFullCustomer && (
                  <>
                    <Col width="6">
                      <p>
                        <strong>Email Address</strong>
                        <br /> {customer.email}
                      </p>
                    </Col>
                    <Col width="6">
                      <p>
                        <strong>Gender</strong>
                        <br /> {customer.gender}
                      </p>
                    </Col>
                    <Col width="6">
                      <p>
                        <strong>Phone Number</strong>
                        <br /> {customer.phone}
                      </p>
                    </Col>
                    <Col width="6">
                      <p>
                        <strong>Mobile Number</strong>
                        <br /> {customer.mobile}
                      </p>
                    </Col>
                    <Col width="6">
                      <p>
                        <strong>Birthday</strong>
                        <br /> {displayDate(customer.birthday)}
                      </p>
                    </Col>
                    <Col width="6">
                      <p>
                        <strong>Anniversary</strong>
                        <br /> {displayDate(customer.anniversary)}
                      </p>
                    </Col>
                  </>
                )}
              </Grid>
            </TabPanel>
            {customer.address && (
              <TabPanel>
                <Grid gutters="md">
                  <Col width="6">
                    <p>
                      <strong>Line 1</strong>
                      <br /> {customer.address.street_1}
                    </p>
                  </Col>
                  <Col width="6">
                    <p>
                      <strong>Line 2</strong>
                      <br /> {customer.address.street_2}
                    </p>
                  </Col>
                  <Col width="6">
                    <p>
                      <strong>City</strong>
                      <br /> {customer.address.city}
                    </p>
                  </Col>
                  <Col width="6">
                    <p>
                      <strong>Region</strong>
                      <br /> {customer.address.region}
                    </p>
                  </Col>
                  <Col width="6">
                    <p>
                      <strong>Postcode</strong>
                      <br /> {customer.address.postcode}
                    </p>
                  </Col>
                  <Col width="6">
                    <p>
                      <strong>Country</strong>
                      <br /> {customer.address.country}
                    </p>
                  </Col>
                </Grid>
              </TabPanel>
            )}
            <TabPanel>
              <Grid gutters="md">
                <Col width="6">
                  <p>
                    <strong>Is VIP?</strong>
                    <br /> {customer.isVip ? 'Yes' : 'No'}
                  </p>
                </Col>
                {customer.isVip && (
                  <Col width="6">
                    <p>
                      <strong>VIP Number</strong>
                      <br /> {customer.vipNumber}
                    </p>
                  </Col>
                )}
              </Grid>
            </TabPanel>
            {customer.freeGifts && (
              <TabPanel>
                {customer.freeGifts.map((gift, index) => (
                  <p key={gift._id}>
                    <strong>Description</strong>
                    <br /> {customer.freeGifts[index].description}
                  </p>
                ))}

                {!customer.freeGifts.length && <p>No free gifts given to customer.</p>}

                {!customer.freeGifts && <p>You cannot view this data</p>}
              </TabPanel>
            )}
            {customer.notes && (
              <TabPanel>
                <NotesList
                  notes={customer.notes}
                  user={auth.user}
                  addNote={addNote}
                  deleteNote={deleteNote}
                  noNotesMessage="No notes added to customer"
                />
              </TabPanel>
            )}
            <TabPanel>
              <Grid gutters="md">
                <Col width="6">
                  <p>
                    <strong>Signup Store</strong>
                    <br /> {customer.signupStore}
                  </p>
                </Col>
                <Col />
                <Col width="6">
                  <p>
                    <strong>Date Added</strong>
                    <br /> {displayDate(customer.createdAt)}
                  </p>
                </Col>
                <Col width="6">
                  <p>
                    <strong>Last Updated</strong>
                    <br /> {displayDate(customer.updatedAt)}
                  </p>
                </Col>
              </Grid>
            </TabPanel>
            <TabPanel>
              {customer.purchases.length < 1 && (
                <p>
                  Customer has made no purchases{auth.user.role === 'staff' ? ' at your store' : ''}
                  .
                </p>
              )}

              {customer.purchases.length > 0 && (
                <table className={styles.purchases}>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th className={styles.price}>Invoice Price</th>
                      <th className={styles.invoice}>Invoice No.</th>
                      <th className={styles.purchased}>Purchase Date</th>
                      <th className={styles.store}>Store</th>
                      <th className={styles.info}>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customer.purchases.map(purchase => (
                      <tr key={purchase._id}>
                        <td>{purchase.description}</td>
                        <td className={styles.price}>{money(purchase.invoicePrice)}</td>
                        <td className={styles.invoice}>{purchase.invoiceNumber}</td>
                        <td className={styles.purchased}>{displayDate(purchase.purchaseDate)}</td>
                        <td className={styles.store}>{purchase.store}</td>
                        <td className={styles.info}>
                          <Link to={`/purchases/${purchase._id}`} className="primary">
                            More info &raquo;
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </>
  );
};

export default ViewCustomer;

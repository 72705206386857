import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

// Components
import { Grid, Col } from '../../components/Grid';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Filters from '../../components/Filters';
import Pagination from '../../components/Pagination';
import ResultsTable from '../../components/ResultsTable';

// Services, Config etc.
import { STORES } from '../../config';
import { fetchCustomers } from '../../services/customers';
import { useFlash } from '../../components/FlashMessage';

const Users = ({ auth }) => {
  const { search } = useLocation();
  const history = useHistory();
  const [showFilters, setShowFilters] = useState(false);
  const [customers, setCustomers] = useState(null);
  const { showFlash } = useFlash();

  // Perform request whenever the query string changes
  useEffect(() => {
    const defaultQuery = { sortBy: 'createdAt', order: 'asc', signupStore: [], page: 1 };
    const currentQuery = queryString.parse(search);

    fetchCustomers({
      ...defaultQuery,
      ...currentQuery
    })
      .then(setCustomers)
      .catch(error => showFlash('error', error));
  }, [search, showFlash]);

  return (
    <>
      <Grid>
        <Col>
          <Button format="secondary" onClick={() => setShowFilters(true)}>
            <Icon icon="filter" /> Show filters
          </Button>
        </Col>
        <Col width="auto">
          <h1>Customers</h1>
        </Col>
        <Col className="range-right">
          <Button format="primary" href="/customers/add">
            + Add Customer
          </Button>

          {auth.user.role === 'admin' && (
            <Button format="secondary" href="/api/customers/export" download>
              Export
            </Button>
          )}
        </Col>
      </Grid>

      <Filters
        open={showFilters}
        onClose={() => setShowFilters(false)}
        options={[
          {
            label: 'Signup Store',
            name: 'signupStore',
            type: 'multiple',
            options: STORES
          },
          {
            label: 'Is VIP',
            name: 'isVip',
            type: 'multiple',
            options: [
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]
          },
          {
            label: 'Gender',
            name: 'gender',
            type: 'multiple',
            options: ['Male', 'Female']
          }
        ]}
      />

      {customers && customers.records.length > 0 && (
        <>
          <ResultsTable
            config={{
              columns: [
                {
                  key: 'email',
                  label: 'Email',
                  sortable: true
                },
                {
                  key: 'firstname',
                  label: 'First Name',
                  sortable: true
                },
                {
                  key: 'lastname',
                  label: 'Last Name',
                  sortable: true
                },
                {
                  key: 'createdAt',
                  label: 'Date Added',
                  sortable: true,
                  format: 'date'
                },
                {
                  key: 'updatedAt',
                  label: 'Last Updated',
                  sortable: true,
                  format: 'date'
                },
                {
                  key: 'signupStore',
                  label: 'Signup Store',
                  sortable: false
                }
              ],
              onRowClick: _id => history.push(`/customers/${_id}`)
            }}
            rows={customers.records}
          />
          <Pagination
            total={customers.total}
            perPage={customers.perPage}
            currentPage={customers.page}
          />
        </>
      )}

      {customers && customers.records.length < 1 && (
        <p className="lg range-center">No customers found mathcing your query</p>
      )}
    </>
  );
};

export default Users;

import axios from 'axios';

export const fetchCustomers = query =>
  new Promise((resolve, reject) => {
    axios
      .get('/api/customers', { params: query })
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const fetchCustomerById = id =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/customers/${id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const addCustomer = customer =>
  new Promise((resolve, reject) => {
    axios
      .post('/api/customers', customer)
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const updateCustomer = (customerId, updates) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/api/customers/${customerId}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const deleteCustomer = id =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/api/customers/${id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const searchCustomers = (field, searchTerm) =>
  new Promise((resolve, reject) => {
    axios
      .get('/api/customers/search', { params: { [field]: searchTerm } })
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const addCustomerNote = (customerId, note) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/api/customers/${customerId}/notes`, note)
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

export const deleteCustomerNote = (customerId, noteId) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/api/customers/${customerId}/notes/${noteId}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err.response.data));
  });

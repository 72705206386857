export default {
  description: '',
  itemType: '',
  fingerSize: '',

  specialOrder: '',
  orderNumber: '',
  stockNumber: '',

  invoicePrice: '',
  rrp: '',
  invoiceNumber: '',
  paymentMethod: '',
  store: '',
  servedBy: '',
  purchaseDate: '',
  saleType: '',
  taxFree: '',

  insuranceTaken: '',
  wearerDob: '',

  complimentaryGift: false,
  complimentaryGiftDetails: '',

  notes: [],

  warranty_check_1: {
    dueDate: '',
    dateReceived: '',
    notes: ''
  },
  warranty_check_2: {
    dueDate: '',
    dateReceived: '',
    notes: ''
  }
};

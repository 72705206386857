import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Formik, Form, getIn } from 'formik';
import addYears from 'date-fns/addYears';

// Components
import Button from '../../components/Button';
import { Grid, Col } from '../../components/Grid';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '../../components/Tabs';
import NotesList from '../../components/NotesList';
import Modal from '../../components/Modal';
import FormField from '../../components/FormField';

// Misc
import {
  fetchPurchaseById,
  purchaseInsurancePolicySent,
  reportPurchaseIssue,
  deletePurchaseNote,
  addPurchaseNote,
  updatePurchaseWarranty
} from '../../services/purchases';
import { fetchRepairsByPurchaseId } from '../../services/repairs';
import { useFlash } from '../../components/FlashMessage';
import { money } from '../../utils/money';
import { inputDate, displayDate } from '../../utils/dates';

import styles from './Purchase.module.scss';

const EditPurchase = ({ auth }) => {
  const { purchaseId } = useParams();
  const [purchase, setPurchase] = useState(null);
  const [repairs, setRepairs] = useState([]);

  const [error, setError] = useState(null);
  const { showFlash, redirectWithFlash } = useFlash();
  const [showIssueModal, setShowIssueModal] = useState(false);

  const fetchPurchaseAndRepairs = () => {
    fetchPurchaseById(purchaseId)
      .then(purchase => {
        setPurchase(purchase);
        return fetchRepairsByPurchaseId(purchase._id);
      })
      .then(repairs => {
        setRepairs(repairs);
      })
      .catch(error => {
        setError(error);
      });
  };

  useEffect(fetchPurchaseAndRepairs, [purchaseId]);

  const addNote = note => {
    addPurchaseNote(purchaseId, note)
      .then(() => {
        fetchPurchaseAndRepairs();
        showFlash('success', 'Note added');
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  const updateWarranty = values => {
    console.log(purchaseId, values);

    updatePurchaseWarranty(purchaseId, values)
      .then(res => {
        showFlash('success', 'Warranty details updated');
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  const deleteNote = noteId => {
    deletePurchaseNote(purchaseId, noteId)
      .then(() => {
        fetchPurchaseAndRepairs();
        showFlash('success', 'Note deleted');
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  const handleIssueSubmit = ({ issue }) =>
    reportPurchaseIssue(purchaseId, issue)
      .then(() =>
        redirectWithFlash(
          '/uninsured-purchases',
          'success',
          'The issue has been reported. You will be notified when it is resolved.'
        )
      )
      .catch(error => {
        setShowIssueModal();
        showFlash('error', error);
      });

  const markAsComplete = () => {
    purchaseInsurancePolicySent(purchaseId)
      .then(() =>
        redirectWithFlash('/uninsured-purchases', 'success', 'Purchase marked as complete!')
      )
      .catch(error => showFlash('error', error));
  };

  const canEditPurchase = auth.user.role === 'admin' || auth.user.role === 'ho';

  const isOverdueWarrantyCheck =
    purchase &&
    purchase.nextWarrantyCheckDueDate &&
    new Date(purchase.nextWarrantyCheckDueDate) <= new Date();

  return (
    <>
      <Grid>
        <Col>
          <Link to={auth.user.role === 'assetsure' ? '/uninsured-purchases' : '/purchases'}>
            &laquo; Back to Purchases
          </Link>
        </Col>
        <Col width="auto">
          <h1>Purchase Details</h1>
        </Col>
        <Col className="range-right">
          {canEditPurchase && <Button href={`/purchases/${purchaseId}/edit`}>Edit Purchase</Button>}
        </Col>
      </Grid>

      {error && <p className="lg range-center error">{error}</p>}

      {purchase && (
        <>
          {isOverdueWarrantyCheck && (
            <div className={styles['purchase-warning']}>
              This purchase is overdue a warranty check!
            </div>
          )}
          {purchase.issue && (
            <div className={styles['purchase-issue']}>
              Assetsure have reported an issue with the details of this purchase.{' '}
              {auth.user.role === 'assetsure' ? (
                'Please wait for somebody to resolve the issue.'
              ) : (
                <>
                  Please <Link to={`/purchases/${purchaseId}/edit`}>edit the purchase details</Link>{' '}
                  to resolve the issue.
                </>
              )}
            </div>
          )}
          <Tabs>
            <TabList>
              <Tab>Item Details</Tab>
              {auth.user.role !== 'assetsure' && <Tab>Stock Details</Tab>}
              <Tab>Sale Details</Tab>
              <Tab>Insurance</Tab>
              {auth.user.role !== 'assetsure' && <Tab>Complimentary Gift</Tab>}
              {auth.user.role !== 'assetsure' && <Tab>Notes ({purchase.notes.length})</Tab>}
              {auth.user.role !== 'assetsure' && <Tab>Warranty</Tab>}
              {purchase.customer && <Tab>Customer</Tab>}
              {repairs.length > 0 && <Tab>Repair History</Tab>}
            </TabList>

            <TabPanels>
              <TabPanel>
                <Grid gutters="md">
                  <Col width="12">
                    <strong>Item Description</strong>
                    <br />
                    {purchase.description}
                  </Col>
                  <Col width="6">
                    <strong>Item Type</strong>
                    <br />
                    {purchase.itemType}
                  </Col>
                  <Col width="6">
                    {purchase.itemType.includes('Ring') && (
                      <>
                        <strong>Finger Size</strong>
                        <br />
                        {purchase.fingerSize}
                      </>
                    )}
                  </Col>
                </Grid>
              </TabPanel>

              {auth.user.role !== 'assetsure' && (
                <TabPanel>
                  <Grid gutters="md">
                    <Col width="6">
                      <strong>Stock Number</strong>
                      <br />
                      {purchase.stockNumber}
                    </Col>
                    <Col width="6">
                      <strong>Order Number</strong>
                      <br />
                      {purchase.orderNumber}
                    </Col>
                    <Col width="6">
                      <strong>Special Order</strong>
                      <br />
                      {purchase.specialOrder ? 'Yes' : 'No'}
                    </Col>
                  </Grid>
                </TabPanel>
              )}

              <TabPanel>
                <Grid gutters="md">
                  <Col width="6">
                    <strong>RRP</strong>
                    <br />
                    {money(purchase.rrp)}
                  </Col>
                  <Col width="6">
                    {auth.user.role !== 'assetsure' && (
                      <>
                        <strong>Invoice Price</strong>
                        <br />
                        {money(purchase.invoicePrice)}
                      </>
                    )}
                  </Col>
                  {auth.user.role !== 'assetsure' && (
                    <>
                      <Col width="6">
                        <strong>Invoice Number</strong>
                        <br />
                        {purchase.invoiceNumber}
                      </Col>
                      <Col width="6">
                        <strong>Payment Method</strong>
                        <br />
                        {purchase.paymentMethod}
                      </Col>
                    </>
                  )}

                  <Col width="6">
                    <strong>Purchased From</strong>
                    <br />
                    {purchase.store}
                  </Col>
                  <Col width="6">
                    <strong>Served By</strong>
                    <br />
                    {`${purchase.servedBy.firstname} ${purchase.servedBy.lastname}`}
                  </Col>
                  <Col width="6">
                    <strong>Purchase Date</strong>
                    <br />
                    {displayDate(purchase.purchaseDate)}
                  </Col>
                </Grid>

                {auth.user.role !== 'assetsure' && (
                  <>
                    <br />
                    <br />
                    <Grid gutters="md">
                      <Col width="6">
                        <strong>Type of Sale</strong>
                        <br />
                        {purchase.saleType}
                      </Col>
                      <Col width="6">
                        <strong>Tax Free?</strong>
                        <br />
                        {purchase.taxFree ? 'Yes' : 'No'}
                      </Col>
                    </Grid>
                  </>
                )}
              </TabPanel>

              <TabPanel>
                <Grid gutters="md">
                  <Col width="6">
                    <strong>Insurance Taken</strong>
                    <br />
                    {purchase.insuranceTaken ? 'Yes' : 'No'}
                  </Col>
                  <Col width="6">
                    {purchase.insuranceTaken && (
                      <>
                        <strong>Wearer DOB</strong>
                        <br />
                        {displayDate(purchase.wearerDob)}
                      </>
                    )}
                  </Col>
                </Grid>
              </TabPanel>

              {auth.user.role !== 'assetsure' && (
                <TabPanel>
                  {purchase.complimentaryGift ? (
                    <>
                      <strong>Gift Details</strong>
                      <br />
                      {purchase.complimentaryGiftDetails}
                    </>
                  ) : (
                    <p>No complementary gift given.</p>
                  )}
                </TabPanel>
              )}
              {auth.user.role !== 'assetsure' && (
                <TabPanel>
                  <NotesList
                    notes={purchase.notes}
                    user={auth.user}
                    addNote={addNote}
                    deleteNote={deleteNote}
                    noNotesMessage="No notes added to purchase"
                  />
                </TabPanel>
              )}
              {auth.user.role !== 'assetsure' && (
                <TabPanel>
                  {purchase.saleType !== 'New' ? (
                    <p className="lg">
                      This purchase is not eligible for a warranty as it is not a new item.
                    </p>
                  ) : (
                    <>
                      <h4>
                        Warranty Expires:{' '}
                        {displayDate(addYears(new Date(purchase.purchaseDate), 3))}
                      </h4>
                      <Formik
                        initialValues={{
                          warranty_check_1: {
                            dueDate: inputDate(getIn(purchase, 'warranty_check_1.dueDate', null)),
                            dateReceived: inputDate(
                              getIn(purchase, 'warranty_check_1.dateReceived', null)
                            ),
                            notes: getIn(purchase, 'warranty_check_1.notes', '')
                          },
                          warranty_check_2: {
                            dueDate: inputDate(getIn(purchase, 'warranty_check_2.dueDate', null)),
                            dateReceived: inputDate(
                              getIn(purchase, 'warranty_check_2.dateReceived', null)
                            ),
                            notes: getIn(purchase, 'warranty_check_2.notes', '')
                          }
                        }}
                        onSubmit={updateWarranty}
                      >
                        {({ values }) => (
                          <Form>
                            {[1, 2].map(year => (
                              <div key={`warranty-${year}`} style={{ margin: '30px 0' }}>
                                <h5>Warranty Check {year}</h5>
                                <Grid gutters="md">
                                  <Col width="6">
                                    <FormField
                                      type="date"
                                      disabled
                                      name={`warranty_check_${year}.dueDate`}
                                      label="Due Date"
                                    />
                                  </Col>
                                  <Col width="6">
                                    <FormField
                                      type="date"
                                      name={`warranty_check_${year}.dateReceived`}
                                      label="Received in Store"
                                      max={new Date()}
                                      disabled={
                                        // Only admin users can update if it has already been filled
                                        values[`warranty_check_${year}`]?.dateReceived &&
                                        auth.user.role !== 'admin'
                                      }
                                    />
                                  </Col>
                                  <Col width="12">
                                    <FormField
                                      type="textarea"
                                      name={`warranty_check_${year}.notes`}
                                      label="Notes"
                                      disabled={
                                        // Only admin users can update if it has already been filled
                                        values[`warranty_check_${year}`]?.notes &&
                                        auth.user.role !== 'admin'
                                      }
                                    />
                                  </Col>
                                </Grid>
                              </div>
                            ))}
                            <p className="range-right">
                              <Button type="submit">Update Warranty Details</Button>
                            </p>
                          </Form>
                        )}
                      </Formik>
                    </>
                  )}
                </TabPanel>
              )}
              {purchase.customer && (
                <TabPanel>
                  <Grid gutters="md">
                    <Col width="6">
                      <strong>First Name:</strong>
                      <br />
                      {purchase.customer.firstname}
                    </Col>
                    <Col width="6">
                      <strong>Last Name:</strong>
                      <br />
                      {purchase.customer.lastname}
                    </Col>
                    <Col width="6">
                      <strong>Phone:</strong>
                      <br />
                      {purchase.customer.phone}
                    </Col>
                    <Col width="6">
                      <strong>Mobile:</strong>
                      <br />
                      {purchase.customer.mobile}
                    </Col>
                    <Col width="6">
                      <strong>Email Address:</strong>
                      <br />
                      {purchase.customer.email}
                    </Col>
                    <Col width="6">
                      <strong>Address:</strong>
                      <br />
                      {[
                        purchase.customer.address.street_1,
                        purchase.customer.address.street_2,
                        purchase.customer.address.city,
                        purchase.customer.address.region,
                        purchase.customer.address.postcode,
                        purchase.customer.address.country
                      ]
                        .filter(line => line)
                        .map(line => (
                          <div key={line}>{line}</div>
                        ))}
                    </Col>
                  </Grid>
                </TabPanel>
              )}
              {repairs.length > 0 && (
                <TabPanel>
                  <table>
                    <thead>
                      <tr>
                        <th>Repair Description</th>
                        <th>Date Booked</th>
                        <th>Store</th>
                        <th>Latest Progress</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {repairs.map(repair => {
                        const lastProgress = repair.progress[repair.progress.length - 1];
                        return (
                          <tr key={repair._id}>
                            <td>{repair.repairDescription}</td>
                            <td className={styles.date}>{displayDate(repair.dateBooked)}</td>
                            <td>{repair.store}</td>
                            <td>{lastProgress ? lastProgress.event : 'None'}</td>
                            <td>
                              <Link to={`/repairs/${repair._id}`} className="primary">
                                More info &raquo;
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>

          {auth.user.role === 'assetsure' && !purchase.issue && (
            <>
              <p className="range-right">
                <Button format="danger" onClick={() => setShowIssueModal(true)}>
                  Report Issue
                </Button>
                <Button format="primary" onClick={markAsComplete}>
                  Mark as Complete
                </Button>
              </p>

              <Modal isOpen={showIssueModal} onClose={() => setShowIssueModal(false)}>
                <h3>Report an issue</h3>
                <Formik
                  onSubmit={handleIssueSubmit}
                  initialValues={{ issue: '' }}
                  validate={values => {
                    if (!values.issue) {
                      return {
                        issue: 'Please enter a description of the issue'
                      };
                    }
                    return {};
                  }}
                >
                  <Form>
                    <FormField name="issue" type="textarea" label="Description" />
                    <p className="range-right">
                      <Button type="submit">Submit</Button>
                    </p>
                  </Form>
                </Formik>
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
};

export default EditPurchase;

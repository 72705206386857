import axios from 'axios';
import cookie from 'js-cookie';

export const login = async credentials => {
  try {
    await axios.post('/api/auth/login', credentials);
    return;
  } catch (error) {
    throw Error(error.response.data);
  }
};

export const logout = () => {
  cookie.remove('token');
};

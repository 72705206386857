import React from 'react';
import classnames from 'classnames';

import styles from './Icon.module.scss';

const Icon = ({ icon, className = null }) => {
  let iconPaths = null;

  switch (icon) {
    case 'trash':
      iconPaths = (
        <>
          <path
            stroke="currentColor"
            strokeWidth="2"
            strokeLinejoin="round"
            d="M6 29h18V8H6z"
            fill="none"
          />
          <path stroke="currentColor" strokeWidth="2" d="M11 12v13m4-13v13m4-13v13" fill="none" />
          <path
            stroke="currentColor"
            strokeWidth="2"
            d="M9 4l12-2"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </>
      );
      break;
    case 'close':
      iconPaths = (
        <path d="M29.24 25.56a2.6 2.6 0 0 1-3.68 3.68L15 18.67 4.43 29.24a2.6 2.6 0 0 1-3.67-3.68L11.32 15 .76 4.43A2.6 2.6 0 0 1 4.43.76L15 11.32 25.56.76a2.6 2.6 0 0 1 3.68 3.67L18.67 15z" />
      );
      break;
    case 'filter':
      iconPaths = (
        <path d="M18.84 7a1.35 1.35 0 0 1-.4-1 1.36 1.36 0 0 1 1.46-1.21 1.37 1.37 0 0 1 1.27 1.27 1.37 1.37 0 0 1-1.38 1.36 1.39 1.39 0 0 1-.95-.42zm2.82 2.26a9.72 9.72 0 0 1-3.81.62 8.74 8.74 0 0 1-2.21-.37 12.75 12.75 0 0 0-3.12-.48 11.3 11.3 0 0 0-3.82.72l-4.8-7h22.18zm-5 7.29a1.41 1.41 0 0 0-.3.76v9.93h-2.73v-9.92a1.44 1.44 0 0 0-.3-.77l-3.24-4.73a7.5 7.5 0 0 1 2.4-.39 9.4 9.4 0 0 1 2.53.39 10.55 10.55 0 0 0 2.79.44 14.34 14.34 0 0 0 1.85-.1zM29.85.71a1.35 1.35 0 0 0-1.2-.71H1.34A1.38 1.38 0 0 0 .13.71a1.37 1.37 0 0 0 .15 1.43L10.9 17.73v10.33A1.83 1.83 0 0 0 12.62 30h4.67a1.82 1.82 0 0 0 1.8-1.85V17.73L29.71 2.14a1.35 1.35 0 0 0 .14-1.43z" />
      );
      break;
    default:
      console.warn(`No '${icon}' icon exists!`);
  }

  if (!iconPaths) return null;
  return (
    <svg
      className={classnames(styles.icon, className)}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      {iconPaths}
    </svg>
  );
};

export default Icon;

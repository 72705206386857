import React from 'react';
import classnames from 'classnames';
import { connect, getIn } from 'formik';

import styles from './Checkboxes.module.scss';

const Checkboxes = ({
  name,
  options,
  disabled = false,
  className = null,
  block = false,
  formik
}) => {
  const checkboxOptions = options.map(option =>
    typeof option === 'string'
      ? {
          label: option,
          value: option
        }
      : option
  );

  const handleChange = event => {
    const { value, checked } = event.currentTarget;

    if (checked) {
      formik.setFieldValue(name, [...getIn(formik.values, name), value]);
    } else {
      formik.setFieldValue(name, getIn(formik.values, name).filter(val => val !== value));
    }
    formik.setFieldTouched(name, true);
  };

  return (
    <div className={classnames(styles.checkboxes, className)}>
      {checkboxOptions.map(({ value, label }) => (
        <label className={classnames(styles.checkbox, block && styles.block)} key={value}>
          <input
            className={styles.input}
            type="checkbox"
            name={name}
            id={`${name}-${value}`}
            value={value}
            checked={getIn(formik.values, name).includes(value.toString())}
            disabled={disabled}
            onChange={handleChange}
            onBlur={formik.handleBlur}
          />
          <span className={styles.label}>{label}</span>
        </label>
      ))}
    </div>
  );
};

export default connect(Checkboxes);

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Components
import { Grid, Col } from '../components/Grid';
import SettingsForm from '../components/SettingsForm';

import { useFlash } from '../components/FlashMessage';

// Services
import { fetchSettings, updateSettings } from '../services/settings';

const SettingsPage = () => {
  const { showFlash, redirectWithFlash } = useFlash();
  const [settings, setSettings] = useState(null);

  // Fetch the settings
  useEffect(() => {
    fetchSettings()
      .then(settings => {
        setSettings(settings);
      })
      .catch(error => {
        setSettings(null);
        showFlash('error', error);
      });
  }, [showFlash]);

  const handleSubmit = values => {
    updateSettings(values)
      .then(() => {
        redirectWithFlash('/settings', 'success', 'Settings updated!');
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  return (
    <>
      <Grid>
        <Col>
          <Link to="/">&laquo; Back to Dashboard</Link>
        </Col>
        <Col width="auto">
          <h1>Settings</h1>
        </Col>
        <Col></Col>
      </Grid>
      {settings && <SettingsForm initialValues={settings} onSubmit={handleSubmit} />}
    </>
  );
};

export default SettingsPage;

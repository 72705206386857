import React from 'react';
import classnames from 'classnames';

import FlashMessage from '../FlashMessage';
import Header from '../Header';

import styles from './Layout.module.scss';

const Page = ({ auth, children, dark = false, centered = false }) => {
  return (
    <div className={styles.wrapper}>
      {auth && <Header auth={auth} />}
      <FlashMessage />
      <main
        className={classnames([
          styles['page-content'],
          dark ? styles.dark : null,
          centered ? styles.centered : null
        ])}
      >
        {children}
      </main>
    </div>
  );
};

export default Page;

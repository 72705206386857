import { inputDate } from '../../utils/dates';

export default {
  itemType: '',
  purchase: '',
  itemDescription: '',
  dateBooked: inputDate(new Date()),
  store: '',
  repairNumber: '',
  repairDescription: '',
  freeOfCharge: '',
  quotedCost: '',
  chargeableCost: '',
  notes: []
};

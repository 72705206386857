import React from 'react';
import { useHistory } from 'react-router-dom';

import SearchBar from '../../components/SearchBar';
import { searchCustomers } from '../../services/customers';

const QuickSearchCustomers = () => {
  const history = useHistory();

  return (
    <>
      <h1>Quick Search Customers</h1>
      <p className="lg range-center">
        Use the search bar below to search for an existing customer.
      </p>

      <SearchBar
        fieldOptions={[
          { value: 'email', label: 'Email Address' },
          { value: 'vipNumber', label: 'VIP Number' },
          { value: 'name', label: 'Name' },
          { value: 'phone', label: 'Landline Number' },
          { value: 'mobile', label: 'Mobile Number' }
        ]}
        fetchResults={searchCustomers}
        renderSuggestion={(result, field) => {
          if (field === 'name')
            return (
              <strong>
                {result.firstname} {result.lastname}
              </strong>
            );
          return (
            <>
              <strong>{result[field]}</strong> - {result.firstname} {result.lastname}
            </>
          );
        }}
        onSelect={customer => history.push(`/customers/${customer._id}`)}
      />
    </>
  );
};

export default QuickSearchCustomers;

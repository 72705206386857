import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

// Components
import Pagination from '../../components/Pagination';
import ResultsTable from '../../components/ResultsTable';

// Services, Config etc.
import { fetchPurchases } from '../../services/purchases';
import { useFlash } from '../../components/FlashMessage';

import styles from './Purchases.module.scss';

const UninsuredPurchases = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [purchases, setPurchases] = useState(null);
  const { showFlash } = useFlash();

  // Perform request whenever the query string changes
  useEffect(() => {
    const defaultQuery = { sortBy: 'purchaseDate', order: 'asc', page: 1 };
    const currentQuery = queryString.parse(search);

    fetchPurchases({
      ...defaultQuery,
      ...currentQuery
    })
      .then(setPurchases)
      .catch(error => showFlash('error', error));
  }, [search, showFlash]);

  return (
    <>
      <h1>Uninsured Purchases</h1>

      {purchases && purchases.records.length > 0 && (
        <>
          <ResultsTable
            config={{
              columns: [
                {
                  key: 'customer',
                  label: 'Customer',
                  format: 'customer',
                  sortable: false,
                  className: styles.customer
                },
                {
                  key: 'description',
                  label: 'Description',
                  sortable: false
                },
                {
                  key: 'rrp',
                  label: 'RRP',
                  sortable: false,
                  format: 'money',
                  className: styles.price
                },
                {
                  key: 'purchaseDate',
                  label: 'Purchase Date',
                  sortable: false,
                  format: 'date',
                  className: styles.date
                },
                {
                  key: 'issue',
                  label: 'Has Issue?',
                  sortable: false,
                  format: 'boolean',
                  className: styles.issue
                }
              ],
              onRowClick: _id => history.push(`/purchases/${_id}`)
            }}
            rows={purchases.records}
          />
          <Pagination
            total={purchases.total}
            perPage={purchases.perPage}
            currentPage={purchases.page}
          />
        </>
      )}

      {purchases && purchases.records.length < 1 && (
        <p className="range-center">No uninsured purchases</p>
      )}
    </>
  );
};

export default UninsuredPurchases;

import axios from 'axios';

export const fetchPurchases = query =>
  new Promise((resolve, reject) => {
    axios
      .get('/api/purchases', { params: query })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const fetchPurchaseById = id =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/purchases/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const addPurchase = purchase =>
  new Promise((resolve, reject) => {
    axios
      .post('/api/purchases', purchase)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const updatePurchase = (id, updates) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/api/purchases/${id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const updatePurchaseWarranty = (id, updates) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/api/purchases/${id}/warranty`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const deletePurchase = id =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/api/purchases/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const searchPurchases = (field, searchTerm) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/api/purchases/search`, { params: { [field]: searchTerm } })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const reportPurchaseIssue = (id, issue) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/api/purchases/${id}/issue`, { issue })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const purchaseInsurancePolicySent = id =>
  new Promise((resolve, reject) => {
    axios
      .post(`/api/purchases/${id}/policy-sent`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const fetchPurchasesWithWarranties = query =>
  new Promise((resolve, reject) => {
    axios
      .get('/api/purchases/warranties', { params: query })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const addPurchaseNote = (purchaseId, note) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/api/purchases/${purchaseId}/notes`, note)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

export const deletePurchaseNote = (purchaseId, noteId) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/api/purchases/${purchaseId}/notes/${noteId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Grid, Col } from '../../components/Grid';
import UserForm from '../../components/UserForm';

// Misc
import { addUser } from '../../services/users';
import { useFlash } from '../../components/FlashMessage';

const AddUserPage = () => {
  const { showFlash, redirectWithFlash } = useFlash();

  const handleSubmit = values => {
    addUser(values)
      .then(() => {
        redirectWithFlash('/users', 'success', 'User added successfully!');
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  return (
    <>
      <Grid>
        <Col>
          <Link to="/users">&laquo; Back to Users</Link>
        </Col>
        <Col width="auto">
          <h1>Add User</h1>
        </Col>
        <Col></Col>
      </Grid>

      <UserForm onSubmit={handleSubmit} />
    </>
  );
};

export default AddUserPage;

import React from 'react';
import { useHistory, Link } from 'react-router-dom';

// Components
import { Grid, Col } from '../../components/Grid';
import SearchBar from '../../components/SearchBar';

import { searchCustomers } from '../../services/customers';

const AddPurchase = ({ auth }) => {
  const history = useHistory();
  return (
    <>
      <Grid>
        <Col>
          <Link to="/purchases">&laquo; Back to Purchases</Link>
        </Col>
        <Col width="auto">
          <h1>Add Purchase</h1>
        </Col>
        <Col></Col>
      </Grid>
      <p className="lg range-center">Search for a customer below:</p>
      <br />

      <SearchBar
        fieldOptions={[
          { value: 'email', label: 'Email Address' },
          { value: 'vipNumber', label: 'VIP Number' },
          { value: 'name', label: 'Name' },
          { value: 'phone', label: 'Landline Number' },
          { value: 'mobile', label: 'Mobile Number' }
        ]}
        fetchResults={searchCustomers}
        renderSuggestion={(result, field) => {
          if (field === 'name')
            return (
              <strong>
                {result.firstname} {result.lastname}
              </strong>
            );
          return (
            <>
              <strong>{result[field]}</strong> - {result.firstname} {result.lastname}
            </>
          );
        }}
        onSelect={customer => history.push(`/purchases/add/${customer._id}`)}
      />
    </>
  );
};

export default AddPurchase;

import axios from 'axios';

/**
 * Fetch settings from  the api
 * @returns {object} settings
 */
export const fetchSettings = () =>
  new Promise((resolve, reject) => {
    axios
      .get('/api/settings')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

/**
 * Updates settings
 * @param {object} settings the new settings to be updated
 * @returns {object} the updated settings
 */
export const updateSettings = settings =>
  new Promise((resolve, reject) => {
    axios
      .put('/api/settings', settings)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });

import React, { useState, cloneElement } from 'react';
import classnames from 'classnames';

import styles from './Tabs.module.scss';

/**
 * The wrapping component that keeps track of the selected tab index and passes it to all children.
 * @param {any} children should contian <Tablist> with <Tab>s and some correlating <TabPanel>s
 */
export const Tabs = ({ children }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  return React.Children.map(children, child => {
    if (!child || typeof child.type === 'string') return child;

    return cloneElement(child, { currentTabIndex, setCurrentTabIndex });
  });
};

export const TabList = ({ children, currentTabIndex, setCurrentTabIndex }) => {
  return (
    <div role="tablist">
      {React.Children.map(children, (child, index) => {
        if (!child || typeof child.type === 'string') return child;
        return cloneElement(child, {
          isSelected: index === currentTabIndex,
          id: index,
          onClick: () => setCurrentTabIndex(index)
        });
      })}
    </div>
  );
};

export const Tab = ({ isSelected, id, onClick, error = false, children }) => (
  <button
    role="tab"
    aria-selected={isSelected}
    aria-controls={`tabpanel-${id}`}
    id={`tab-${id}`}
    tabIndex={isSelected ? 0 : -1}
    className={classnames(styles.tab, error && styles.error)}
    type="button"
    onClick={onClick}
  >
    {children}
  </button>
);

export const TabPanels = ({ children, currentTabIndex }) => {
  return React.Children.map(children, (child, index) => {
    if (!child || typeof child.type === 'string') return child;
    return cloneElement(child, {
      isSelected: index === currentTabIndex,
      id: index
    });
  });
};

export const TabPanel = ({ isSelected, id, children }) => (
  <div
    className={styles.tabPanel}
    role="tabpanel"
    id={`tabpanel-${id}`}
    aria-labelledby={`tab-${id}`}
    hidden={!isSelected}
  >
    {children}
  </div>
);

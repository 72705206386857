import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { Formik, Form } from 'formik';

import Layout from '../../components/Layout';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Logo from '../../components/Logo';

import styles from './Login.module.scss';

import { login } from '../../services/auth';

const Login = () => {
  const location = useLocation();
  const history = useHistory();
  const [loginError, setLoginError] = useState(null);

  const handleSubmit = credentials => {
    setLoginError(null);
    login(credentials)
      .then(() =>
        history.push(location.state && location.state.redirect ? location.state.redirect : '/')
      )
      .catch(err => {
        setLoginError(err.message);
      });
  };

  return (
    <Layout dark centered>
      <Logo className={styles.logo} />
      <h1 className={styles.welcome}>
        Welcome to the <em>Hatton Garden Vault</em>
      </h1>
      <Formik
        initialValues={{
          username: '',
          password: ''
        }}
        onSubmit={handleSubmit}
      >
        <Form className={styles.form} method="post">
          <p className={`${styles.instructions} lg`}>
            Please enter your details below to&nbsp;login
          </p>

          <p>
            <label htmlFor="username">Username</label>
            <Input type="text" name="username" id="username" />
          </p>

          <p>
            <label htmlFor="password">Password</label>
            <Input type="password" name="password" id="password" />
          </p>

          {loginError && <p className={styles.error}>{loginError}</p>}

          <Button type="submit">Login</Button>
        </Form>
      </Formik>
    </Layout>
  );
};

export default Login;

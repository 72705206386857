import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

// Components
import PurchaseForm from '../../components/PurchaseForm';

// Misc
import { addPurchase } from '../../services/purchases';
import { fetchCustomerById } from '../../services/customers';
import { fetchSettings } from '../../services/settings';
import { useFlash } from '../../components/FlashMessage';

const AddPurchaseToCustomer = ({ auth }) => {
  const { customerId } = useParams();
  const { redirectWithFlash, showFlash } = useFlash();
  const [customer, setCustomer] = useState(null);
  const [settings, setSettings] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the customer
    fetchCustomerById(customerId)
      .then(setCustomer)
      .catch(error => {
        setError(error);
      });
    // Fetch the settings
    fetchSettings()
      .then(setSettings)
      .catch(error => {
        setError(error);
      });
  }, [customerId]);

  const handleSubmit = values => {
    console.log('Page submit: ', values);
    // Add the customer ID to the purchase data
    const data = {
      ...values,
      customer: customerId
    };

    addPurchase(data)
      .then(() => {
        redirectWithFlash(
          '/purchases',
          'success',
          <>
            Purchase added!{' '}
            <Link to={`/purchases/add/${customerId}`}>Click here to add another one</Link>.
          </>
        );
      })
      .catch(error => {
        showFlash('error', error);
      });
  };

  return (
    <>
      {error && <p className="lg range-center error">{error}</p>}
      {settings && customer && (
        <>
          <h1>Add Purchase {customer && `for ${customer.firstname} ${customer.lastname}`}</h1>
          <PurchaseForm
            mode="add"
            onSubmit={handleSubmit}
            maxInsurableValue={settings.maxInsurableValue}
            initialValues={
              auth.user.role === 'staff' ? { store: auth.user.store, servedBy: auth.user._id } : {}
            }
          />
        </>
      )}
    </>
  );
};

export default AddPurchaseToCustomer;

import React from 'react';
import { Formik, Form } from 'formik';

import FormField from '../FormField';
import Button from '../Button';

import defaultValues from './defaultValues';
import validationSchema from './validationSchema';

const NotificationForm = ({ initialValues, onSubmit }) => {
  return (
    <Formik
      initialValues={{ ...defaultValues, ...initialValues }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, submitForm }) => (
        <Form>
          <FormField
            type="checkboxes"
            name="audience"
            id="audience"
            label="Audience"
            options={[
              { label: 'Assetsure', value: 'assetsure' },
              { label: 'Staff', value: 'staff' }
            ]}
          />
          <FormField type="text" name="title" id="title" label="Title" />
          <FormField type="textarea" name="content" id="content" label="Content" rows="8" />
          <p className="range-right">
            <Button
              format="secondary"
              type="button"
              onClick={() => {
                setFieldValue('status', 'draft', false);
                submitForm();
              }}
            >
              Save as Draft
            </Button>
            <Button
              type="button"
              onClick={() => {
                setFieldValue('status', 'published', false);
                submitForm();
              }}
            >
              Save and Publish
            </Button>
          </p>
        </Form>
      )}
    </Formik>
  );
};

export default NotificationForm;

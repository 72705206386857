import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Grid, Col } from '../../components/Grid';
import QuickLinks from '../../components/QuickLinks';

const AddPurchase = ({ auth }) => {
  return (
    <>
      <Grid>
        <Col>
          <Link to="/purchases">&laquo; Back to Purchases</Link>
        </Col>
        <Col width="auto">
          <h1>Add Purchase</h1>
        </Col>
        <Col></Col>
      </Grid>
      <QuickLinks
        links={[
          { href: '/purchases/add/new-customer', text: 'New Customer' },
          { href: '/purchases/add/existing-customer', text: 'Existing Customer' }
        ]}
      />
    </>
  );
};

export default AddPurchase;

import React from 'react';
import { connect, getIn } from 'formik';

import styles from './PasswordHints.module.scss';

export const validatePassword = password => {
  let errors = {
    short: true,
    uppercase: true,
    lowercase: true,
    number: true,
    symbol: true
  };

  errors.short = !password || password.length < 8;
  errors.uppercase = !password || !/[A-Z]/.test(password);
  errors.lowercase = !password || !/[a-z]/.test(password);
  errors.number = !password || !/\d/.test(password);
  errors.symbol = !password || !/[^a-zA-Z\d\s:]/.test(password);

  // Return the error object
  return errors;
};

const PasswordHints = ({ formik }) => {
  const password = getIn(formik, 'values.password');
  const errors = validatePassword(password);

  return (
    <div>
      <p className="tight">
        <strong>Passwords must contain:</strong>
      </p>
      <ul className={styles.hints}>
        <li className={errors.short ? styles.error : styles.success}>At least 8 characters</li>
        <li className={errors.uppercase ? styles.error : styles.success}>
          At least one uppercase character
        </li>
        <li className={errors.lowercase ? styles.error : styles.success}>
          At least one lowercase character
        </li>
        <li className={errors.number ? styles.error : styles.success}>At least one number</li>
        <li className={errors.symbol ? styles.error : styles.success}>At least one symbol</li>
      </ul>
    </div>
  );
};

export default connect(PasswordHints);

import React from 'react';
import { Field } from 'formik';
import Dp from 'react-datepicker';
import MaskedInput from 'react-input-mask';

import classnames from 'classnames';

import styles from './DatePicker.module.scss';

const DatePicker = ({ name, min, max, disabled, className }) => {
  return (
    <div className={classnames(className, styles.datepicker)}>
      <Field
        name={name}
        render={({ field, form }) => (
          <Dp
            selected={field.value && new Date(field.value)}
            onChange={date => {
              form.setFieldValue(name, date);
              form.setFieldTouched(name);
            }}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            placeholderText="DD/MM/YYYY"
            dateFormat="dd/MM/yyyy"
            id={name}
            minDate={min}
            maxDate={max}
            disabled={disabled}
            customInput={<MaskedInput mask="99/99/9999" maskChar=" " />}
          />
        )}
      />
    </div>
  );
};

export default DatePicker;

import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { ReactComponent as LogoSVG } from './logo.svg';

import styles from './Logo.module.scss';

const Logo = ({ className = null }) => (
  <Link to="/" className={classnames(styles.logo, className)}>
    <LogoSVG />
  </Link>
);

export default Logo;
